import axios from 'axios';
// baseURL: 'https://contentenablers.dev/xapice/api/', ------- vibrant
const hostNames = ['localhost', 'assets.contentenablers.dev'];
const jwtAxios = axios.create({
  baseURL:
    hostNames.indexOf(window.location.hostname) !== -1
      ? 'https://tradecompliance.training/xapice/api/'
      : 'https://tradecompliancecourses.com/ceauthor/api/',
  headers: {
    'Content-Type': 'application/json'
  }
});

const apiRequests = async ({
  method,
  url,
  body = undefined,
  headers = undefined,
  params = undefined
}) => {
  const response = {
    data: null,
    error: null,
    loading: true
  };
  await jwtAxios({
    method,
    url,
    data: body,
    headers,
    params
  })
    .then((res) => {
      response.data = res.data;
    })
    .catch((error) => {
      if (error.response) {
        response.error = error.response.data;
      } else if (error.request) {
        response.error = error.request;
      } else {
        response.error = error.message || 'Something went wrong';
      }
    })
    .finally(() => {
      response.loading = false;
    });
  return response;
};

export default apiRequests;
