import { shape } from 'prop-types';
import React from 'react';
import logo from '../../assets/img/logo.svg';

const AppLogo = (props) => {
  const { logoStyle } = props;
  return <img src={logo} alt="Logo" style={logoStyle} {...props} />;
};
AppLogo.defaultProps = {
  logoStyle: {}
};
AppLogo.propTypes = {
  logoStyle: shape()
};

export default AppLogo;
