/* eslint-disable no-useless-rename */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './toolbar.scss';
import FolderSpecialOutlinedIcon from '@material-ui/icons/FolderSpecialOutlined';
import Apps from '@material-ui/icons/Apps';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { Typeahead } from 'react-bootstrap-typeahead';
import AppDrawer from '../AppDrawer/AppDrawer';
import * as assetAction from '../../redux/actions/assetsInfoAction';
import AppButton from '../AppButtons/AppButton';
import AdvanceFilters from '../../containers/AdvancedFilter';
import useDebounce from '../AppHooks/useDebounce';
import IconButtonGroup from '../AppButtons/IconButtonGroup';
import AssetPlayers from '../AssetPlayers';
import NavigationButtons from './NavigationButtons';
import AppLoader from '../AppLoader';
// eslint-disable-next-line camelcase
import clcid_Mapping from '../../clcid_Mapping.json';
// import { highLightMark } from '../../utils/helper';
// eslint-disable-next-line import/order
import Mark from 'mark.js';

function TabToolbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    utilityInfo: { isCardView },
    assetsInfo: {
      filters: filtersInfo,
      topicVideoSearchList: topicVideoSearchList,
      topicVideoSearchList: { archiveCrs, searchContent: searchContent },
      selectedTVCrouse,
      selectedTVCrouse: { selected }
    }
  } = useSelector((state) => state);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedCRS, setSelectedCRS] = useState(filtersInfo?.searchText || '');
  const [checkValue, setCheckValue] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedCourseList, setSelectedCourseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [flattenArchiveCrs, seFlattenArchiveCrs] = useState([]);
  const searchCrs = useDebounce(query, 1500);
  const [isStreaming, setIsStreaming] = useState(false);

  const labels = [
    { label: 'Course Name', value: 'courseName' },
    { label: 'Topic Name', value: 'topicName' },
    { label: 'File Name', value: 'fileName' },
    { label: 'Text Content', value: 'textName' }
  ];

  // const handleToggleView = (view) =>
  //   dispatch(utilityInfoAction.toggleGridView(view === 'grid'));

  const handleWishListRedirection = () => {
    navigate('/wishlist');
  };

  useEffect(() => {
    try {
      if (query?.length > 2) {
        const payload = {
          data: query,
          type: checkValue
        };
        dispatch(assetAction.setSearchContent(payload));
        // eslint-disable-next-line no-empty
      } else {
        setIsLoading(false);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [searchCrs]);

  useEffect(() => {
    setSelectedCourseList(selected);
  }, [selectedTVCrouse]);

  const handleInputChange = (q) => {
    setQuery(q);
    if (checkValue !== 'courseName' && checkValue) setIsLoading(true);
    if (q.length < 1) {
      setSelectedCourseList([]);
      setIsLoading(false);
    }
  };

  const handleSearch = (value) => {
    try {
      const selectedCID = value[0].id;
      dispatch(assetAction.SelectedTopicVideoCrs(selectedCID));
      setSelectedCRS(value[0]);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  useEffect(() => {
    let SearchCrs = [];
    seFlattenArchiveCrs(archiveCrs);
    if (['topicName', 'fileName', 'textName'].indexOf(checkValue) !== -1)
      SearchCrs = searchContent?.filter(
        (item) => item?.filepath !== '0' && item.cid !== 0
      );
    setSelectedCourseList(SearchCrs);
    setIsLoading(false);
  }, [searchContent]);

  useEffect(() => {
    if (['textName'].includes(checkValue)) {
      // eslint-disable-next-line prefer-const
      let markInstance = new Mark(
        document.querySelector(`.highlight-continuer`)
      );
      markInstance.unmark({
        done: () => {
          markInstance.mark(query);
        }
      });
    }
    if (selectedCourseList?.length > 0) {
      const shortedList = selectedCourseList.sort(function (a, b) {
        const textA = a.title.toUpperCase();
        const textB = b.title.toUpperCase();
        // eslint-disable-next-line no-nested-ternary
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setSelectedCourseList(shortedList);
    }
  }, [query, topicVideoSearchList, selectedCourseList, showDrawer]);

  const handleCheckBox = (CheckboxName, checkboxStatus) => {
    setSelectedCourseList([]);
    setQuery('');
    if (checkboxStatus) {
      setCheckValue(CheckboxName);
    } else {
      setCheckValue(null);
    }
  };

  // eslint-disable-next-line consistent-return
  const timeStringFormat = (s) => {
    try {
      const date = new Date(0);
      date.setSeconds(s); // specify value for SECONDS
      const timeString = date.toISOString()?.substring(11, 19);
      return timeString;
    } catch (e) {
      // console.log();
    }
  };

  const handleOnclick = (crs) => {
    let lgPath = '';
    const flattenClcid = flattenArchiveCrs[crs.cid]?.info?.config?.clcid;
    if (flattenClcid) {
      setIsStreaming(false);
      lgPath = `https://d14rwvged4djyq.cloudfront.net/type3/${flattenClcid}/${crs?.filepath}.mp4`;
    } else {
      // eslint-disable-next-line camelcase
      const mappedData = clcid_Mapping[crs?.cid];
      setIsStreaming(mappedData);
      lgPath = `https://d14rwvged4djyq.cloudfront.net/type3/${mappedData?.clcid}/${crs?.filepath}.mp4`;
    }
    setShowDrawer(!showDrawer);
    setSelectedCourse({ ...crs, filepath: lgPath, path: lgPath, rtype: 4 });
    if (typeof window !== 'undefined' && window?.document && !showDrawer) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  return (
    <div className="tab-tool-bar">
      <div className="tool-bar">
        {/* Toolbar Top links */}
        <div className="row">
          <div className="col-6 col-lg-6 mb-30">
            <div>Search By</div>
            <FormGroup className="check-box-continuer">
              {labels?.map((item, key) => {
                return (
                  <FormControlLabel
                    key={key}
                    className="mr-40"
                    control={
                      <Checkbox
                        id={item?.value}
                        checked={checkValue === item?.value}
                        className="chk-box"
                        onChange={(e) =>
                          handleCheckBox(e.target.id, e.target.checked)
                        }
                      />
                    }
                    label={item.label}
                  />
                );
              })}
            </FormGroup>
            {checkValue === null && query?.length > 2 && (
              <p className="select-warning fw-600 m-0">
                ***Please select Your Category
              </p>
            )}
          </div>

          <div className="col-6 col-lg-6 text-end">
            <button
              type="button"
              className="unset-all d-inline-flex align-items-center"
              onClick={handleWishListRedirection}
            >
              <FolderSpecialOutlinedIcon />
              <span className="pl-10 fw-500">My Wishlist</span>
            </button>
          </div>
        </div>

        {/* Searchbox, Tabs and Grid & Listview btns */}
        <div className="row">
          <div className="col-12 col-md-12 d-flex align-items-start mb-10">
            <div className="toolbar-searchbox-wrapper">
              <div className="toolbar-searchbox rbt-searchbox">
                {checkValue === 'courseName' ? (
                  <Typeahead
                    id="search"
                    maxResults={false}
                    minLength={2}
                    options={flattenArchiveCrs}
                    className="optional-search"
                    placeholder="Search"
                    aria-label="search"
                    aria-describedby="search"
                    onChange={handleSearch}
                    isLoading={isLoading}
                    clearButton
                    onInputChange={handleInputChange}
                    renderMenuItemChildren={(option) => (
                      <div key={option?.id}>
                        <span>{option?.label}</span>
                      </div>
                    )}
                  />
                ) : (
                  <>
                    {/* <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div> */}
                    <div className="input-group spinner-input-group">
                      <input
                        placeholder="Search"
                        aria-label="search"
                        aria-describedby="search"
                        className={`${isLoading && 'spinning'} input-search`}
                        onChange={(e) => handleInputChange(e.target.value)}
                        value={query}
                      />
                      {isLoading && (
                        <div className="input-group-append">
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="sr-only" />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Tabs */}
            <NavigationButtons />

            {/* Grid & Listview btns */}
            <div className="grid-list-icons">
              <AppButton
                classes={`${isCardView && 'active'} gridtype unset-all`}
                onlyIcon
                // handleClick={() => handleToggleView('grid')}
                name={<Apps className="cl-icons" />}
              />
              <AppButton
                classes={`${!isCardView && 'active'} gridtype unset-all`}
                onlyIcon
                // handleClick={() => handleToggleView('list')}
                name={<FormatListBulleted className="cl-icons ml10" />}
              />
            </div>
          </div>
        </div>
      </div>
      <AdvanceFilters showFilter={showFilter} setShowFilter={setShowFilter} />
      <div className="highlight-continuer">
        {selectedCourseList?.length > 0 ? (
          <div className="assets-topic-list">
            {selectedCourseList.map((course) => {
              // eslint-disable-next-line no-undef, no-unused-vars
              return (
                <div key={course?.ccid} onClick={() => handleOnclick(course)}>
                  <div className="col-12 tab-video-list">
                    {/* cards */}
                    <div className="listing-card list-view card mb-3">
                      <div className="card-body no-pointer">
                        <div className="course-card-img px-10">
                          <i className="cus-icons tv-playbtn mx-10 mt-10 " />
                          <p className="time-duration">
                            {' '}
                            {timeStringFormat(course?.duration)}{' '}
                          </p>
                        </div>

                        <div className="d-flex flex-column px-10 py-10">
                          <h2 className="card-title mb-2">{course?.title}</h2>
                          <p className="card-topic mb-2 fw-500">
                            {flattenArchiveCrs[course.cid]?.coursename}
                          </p>
                          <div className="card-text crs-text pb-2">
                            <div className="list-info">
                              <div
                                // eslint-disable-next-line react/no-danger
                                className="dis"
                                dangerouslySetInnerHTML={{
                                  __html: course?.ctext
                                }}
                              />
                              {/* <p className="extension">...</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {isLoading && flattenArchiveCrs.length > 0 ? (
              // <h3 className="fw-600 no-data"><AppLoader/></h3>
              <span className="inner-loader">
                <AppLoader />
              </span>
            ) : (
              query.length > 0 && <p className="no-data">No data found</p>
            )}
          </>
        )}
        {showDrawer && (
          <AppDrawer
            showDrawer={showDrawer}
            setShowDrawer={() => handleOnclick(NaN)}
          >
            <div className="offcanvas-body">
              {/* Image Detials */}
              <div className="image-details-container">
                <div className="iv-detail-box mb-30">
                  <div className="iv-image">
                    <AssetPlayers
                      rtype="2"
                      path={selectedCourse?.filepath || ''}
                      name={selectedCourse?.title}
                      view="modal"
                      controls
                      info={{}}
                    />
                  </div>
                  <div className="iv-details">
                    <div className="d-flex align-items-start">
                      <h4 className="fw-600 mb-1">
                        {selectedCourse?.title} &nbsp;
                      </h4>
                    </div>
                    {isStreaming && (
                      <p className="not-migrated-msg mb-0">
                        (&nbsp;{isStreaming?.courseName} is not migrated &nbsp;)
                      </p>
                    )}
                    <div className="d-flex align-items-start" />
                    <div className="d-flex align-items-start">
                      <h5 className="fw-600 mb-3">{selectedCRS?.label}</h5>
                    </div>
                    <div className="liks-btns">
                      <IconButtonGroup
                        id={String(selectedCourse?._id)}
                        data={selectedCourse}
                        hasWishlist
                        tabVideo
                      />
                    </div>
                  </div>
                  <div className="text-dis">
                    <h4 className="p-20  fw-600 Lg-text-heading">
                      Text Version
                    </h4>
                    {selectedCourse?.ctext ? (
                      <div
                        // eslint-disable-next-line react/no-danger
                        className="mark-content dis p-20"
                        dangerouslySetInnerHTML={{
                          __html: selectedCourse?.ctext
                        }}
                      />
                    ) : (
                      <p className="dis p-20"> No description text found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </AppDrawer>
        )}
      </div>
    </div>
  );
}

export default TabToolbar;
