import React from 'react';
import { string } from 'prop-types';
import { useField } from 'formik';

const AppRadio = (props) => {
  const { classes, label, name, value } = props;
  const [field, meta] = useField(props);
  return (
    <label className={`custom-radio-container ${classes}`}>
      {label}
      <input
        type="radio"
        name={name}
        {...field}
        {...props}
        checked={meta.value === value}
      />
      <span className="checkmark" />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </label>
  );
};

AppRadio.defaultProps = {
  classes: '',
  value: '',
  label: 'Radio',
  name: 'radioname'
};
AppRadio.propTypes = {
  classes: string,
  label: string,
  name: string,
  value: string
};

export default AppRadio;
