/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setBlockData, getSelectedBlocksMetadata } from 'draftjs-utils';
import './RichTextEditor.scss';
import { EditorState, Modifier } from 'draft-js';

export default class CustomStyle extends Component {
  constructor(props) {
    super(props);
    const { editorState, modalHandler, type } = props;
    const currentSelectedValue =
      getSelectedBlocksMetadata(editorState)?.get(type);
    let option = [];
    if (type === 'line-height') {
      option = options?.lineHeight;
    } else if (type === 'list-style-type') {
      option = options?.listStyle;
    }
    this.state = {
      expanded: false,
      currentSelectedValue: editorState
        ? option?.map((el) => el.value).indexOf(Number(currentSelectedValue)) +
          1
        : undefined,
      option
    };
    modalHandler.registerCallBack(this.expandCollapse);
  }

  componentDidUpdate(prevProps) {
    const { editorState, type } = this.props;
    if (editorState && editorState !== prevProps.editorState) {
      const currentSelectedValue =
        getSelectedBlocksMetadata(editorState).get(type);
      const index = this.state.option
        ?.map((el) => el.value)
        .indexOf(currentSelectedValue);
      this.setState({
        currentSelectedValue: this.state.option[index]?.title
      });
    }
  }

  componentWillUnmount() {
    const { modalHandler } = this.props;
    modalHandler.deregisterCallBack(this.expandCollapse);
  }

  onExpandEvent = () => {
    this.signalExpanded = !this.state.expanded;
  };

  expandCollapse = () => {
    this.setState({
      expanded: this.signalExpanded
    });
    this.signalExpanded = false;
  };

  toggleInlineHeight = (value) => {
    const { editorState, onChange, type } = this.props;
    const currentStyleMap = getSelectedBlocksMetadata(editorState);
    let nextStyleObject = {};
    const text_align = getSelectedBlocksMetadata(editorState)?.get('text-align');
    const padding_left = getSelectedBlocksMetadata(editorState)?.get('padding_left');
    currentStyleMap.forEach((value, key) => {
      nextStyleObject[key] = value;
    });
    const textCheck = text_align
      ? { 'text-align': `${text_align}` }
      : { 'text-align': 'left' };
    const paddingCheck = padding_left ? ' ' : { 'padding-left': '4px' };
    const listStyle =
      type === 'list-style-type'
        ? {
            'list-style-type': value,
            ...paddingCheck,
            'list-style-position': 'outside'
          }
        : { 'line-height': value };
    const styleObject = {
      ...textCheck,
      ...listStyle
    };
    nextStyleObject = { ...nextStyleObject, ...styleObject };

    const newState = setBlockData(editorState, nextStyleObject);
    if (newState) {
      onChange(newState);
    }
  };

  addQuote = (quote) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      quote,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  renderModal = () => {
    return (
      <ul className="rdw-dropdown-optionwrapper">
        {this.state.option?.map((el, index) => {
          return (
            <li
              onClick={() => this.toggleInlineHeight(el.value)}
              className="rdw-dropdownoption-default rdw-fontsize-option customoption-drop-dowen"
              key={index}
              title={el.title}
            >
              {el.title}
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    const { expanded, currentSelectedValue } = this.state;
    const { type } = this.props;
    const defaultLineHeight = Number(
      this.state.option?.map((el) => el.value).indexOf(1.2)
    );
    const selectedValue =
      (currentSelectedValue || expanded) &&
      this.state.option?.length > 0 &&
      defaultLineHeight >= 0 &&
      2;
    const title = type;
    return type === 'quote' ? (
      <>
        <div
          className="quote-symbol"
          title="Quotes"
          onClick={() => this.addQuote('❝')}
        >
          {' '}
          ❝{' '}
        </div>
        <div
          className="quote-symbol"
          title="Quotes"
          onClick={() => this.addQuote('❞')}
        >
          {' '}
          ❞{' '}
        </div>
      </>
    ) : (
      <div
        className="rdw-block-wrapper custom-lineheight"
        onClick={this.onExpandEvent}
      >
        <div
          aria-expanded={expanded}
          className="rdw-dropdown-wrapper rdw-fontsize-dropdown demo-option-custom-medium line-height-dropdown"
          aria-label="rdw-dropdown"
          title={title}
        >
          <a className="rdw-dropdown-selectedtext" title={title}>
            {selectedValue || currentSelectedValue ? (
              <span>{currentSelectedValue}&nbsp;&nbsp;&nbsp;</span>
            ) : type === 'line-height' ? (
              <img
                className="specing-icon"
                src="https://static.thenounproject.com/png/313536-200.png"
                alt="Specing"
              />
            ) : (
              <p>List Style &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
            )}

            <div
              className={
                !expanded
                  ? 'rdw-dropdown-carettoopen'
                  : 'rdw-dropdown-carettoclose'
              }
            />
          </a>
          {expanded ? this.renderModal() : undefined}
        </div>
      </div>
    );
  }
}

export const options = {
  lineHeight: [
    { title: 1, value: 1 },
    { title: 2, value: 1.2 },
    { title: 3, value: 1.5 },
    { title: 4, value: 2 },
    { title: 5, value: 2.5 },
    { title: 6, value: 3 },
    { title: 7, value: 3.5 },
    { title: 8, value: 4 }
  ],
  listStyle: [
    { title: 'UnOrder List', value: 'disc' },
    { title: 'Order List', value: 'decimal' },
    { title: 'Circle', value: 'circle' },
    { title: 'Square', value: 'square' },
    { title: 'Small Roman', value: 'lower-roman' },
    { title: 'Caps Roman', value: 'upper-roman' },
    { title: 'Small Alpha', value: 'lower-alpha' },
    { title: 'Caps Alpha', value: 'upper-alpha' }
  ]
};

CustomStyle.propTypes = {
  /** Func description for tracking the State Changes */
  onChange: PropTypes.func,
  /** Current editorState and datas of content */
  editorState: PropTypes.object,
  /** Func description for tracking the modal open */
  modalHandler: PropTypes.object
};
