import * as types from './types';

const initialState = {
  courseId: -1,
  filteredAsset: [],
  allAsset: null,
  asset: null,
  contentSet: [],
  adminKeyTags: [],
  filteredTags: [],
  assetTags: [],
  filters: null,
  activePage: 0,
  totalActiveAssets: 0,
  NextPage: 0,
  remainingAssets: 0,
  size: 2000,
  topicVideoSearchList: {
    archiveCrs: [],
    searchContent: []
  },
  selectedTVCrouse: {
    selectedList: [],
    selected: []
  },
  midjourney: null
};

const assetsInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILTERED_ASSETS: {
      return {
        ...state,
        filteredAsset: action.payload
      };
    }
    case types.UPDATE_ADMIN_TAGS:
      return {
        ...state,
        adminKeyTags: action.payload
      };
    case types.ADVANCE_FILTER:
      return {
        ...state,
        filteredAsset: action.payload
      };
    case types.GET_ASSET:
      return {
        ...state,
        asset: action.payload
      };
    case types.ALL_ASSETS: {
      return {
        ...state,
        allAsset: action.payload.assets,
        assetTags: action.payload.tags
      };
    }
    case types.SET_ACTIVE_PAGE: {
      return {
        ...state,
        activePage: action.payload.next,
        totalActiveAssets: action.payload.count
      };
    }
    case types.SET_NEXT_PAGE: {
      return {
        ...state,
        NextPage: action.payload.next,
        remainingAssets: action.payload.count,
        size: action.payload.size
      };
    }
    case types.UPDATE_ASSETS:
      return {
        ...state,
        allAsset: { ...state.allAsset, ...action.payload }
      };
    case types.FETCH_CONTENT_SET:
      return {
        ...state,
        contentSet: action.payload
      };
    case types.SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      };
    case types.SET_FILTERS_TAGS:
      return {
        ...state,
        filteredTags: [
          ...new Set(initialState.filteredTags.concat(action.payload))
        ]
      };
    case types.SET_ARCHIVE_COURSES:
      return {
        ...state,
        topicVideoSearchList: {
          ...state?.topicVideoSearchList,
          archiveCrs: action?.payload
        }
      };
    case types.SET_TV_SEARCH_CONTENT:
      return {
        ...state,
        topicVideoSearchList: {
          ...state?.topicVideoSearchList,
          searchContent: action?.payload
        }
      };
    case types.SELECTED_TV_COURSE:
      return {
        ...state,
        selectedTVCrouse: {
          ...action?.payload
        }
      };
    case types.RESET:
      return {
        ...initialState
      };
    case types.RESET_MIDJOURNEY_DATA:
      return {
        ...state,
        midjourney: null
      };
    case types.SET_MIDJOURNEY_DATA:
      console.log(action?.payload);
      return {
        ...state,
        midjourney: action?.payload
      };

    default:
      return state;
  }
};

export default assetsInfoReducer;
