import axios from 'axios';

const jwtAxios = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

const customApiRequests = async ({
  method,
  url,
  body = undefined,
  headers = undefined,
  params = undefined
}) => {
  const response = {
    data: null,
    error: null,
    loading: true
  };
  await jwtAxios({
    method,
    url,
    data: body,
    headers,
    params
  })
    .then((res) => {
      response.data = res.data;
    })
    .catch((error) => {
      if (error.response) {
        response.error = error.response.data;
      } else if (error.request) {
        response.error = error.request;
      } else {
        response.error = error.message || 'Something went wrong';
      }
    })
    .finally(() => {
      response.loading = false;
    });
  return response;
};

export default customApiRequests;
