/* eslint-disable jsx-a11y/media-has-caption */
import { bool, string } from 'prop-types';
import React from 'react';

const AudioPlayer = ({ path, controls }) => {
  return (
    <div className="audio-details">
      <audio className="asset-fit" controls={controls}>
        <source src={path} />
      </audio>

      <i className="cus-icons audioicon" />
    </div>
  );
};
AudioPlayer.defaultProps = {
  controls: false
};
AudioPlayer.propTypes = {
  path: string.isRequired,
  controls: bool
};

export default AudioPlayer;
