/* eslint-disable no-debugger */
/* eslint-disable object-curly-newline */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import AppDrawer from '../../components/AppDrawer/AppDrawer';
import AppTags from '../../components/AppTags/AppTags';
import AppInputGroup from '../../components/AppFormComponents/AppInputGroup';
import AppInput from '../../components/AppFormComponents/AppInput';
import * as utilityAction from '../../redux/actions/utilityInfoAction';
import * as assetAction from '../../redux/actions/assetsInfoAction';
import AppRadio from '../../components/AppFormComponents/AppRadio';
import AppFileInput from '../../components/AppFormComponents/AppFileInput';
import { assetTypeId } from '../../utils/constants';
import { messageError } from '../../components/AppToaster';
import RichTextEditor from '../../components/AppRichTextEditor/RichTextEditor';

const COURSES_CATEGORIES = [
  `Import`,
  `Export`,
  `Sanctions`,
  `Miscellneous`,
  `Security & Trade`,
  `Corruption & Antiboycott`
];

const assetValidation = Yup.object().shape({
  assetTitle: Yup.string().required('Asset title is required'),
  fileId: Yup.string().required('File is required'),
  // contentSet: Yup.string().required('Content set is required'),
  // courseCategory: Yup.array()
  //   .min(1)
  //   .required('Course category tags is required'),
  // courseContent: Yup.array().min(1).required('course content is required'),
  adminTags: Yup.array().min(1).required('Admin tags is required'),
  // customTags: Yup.array().min(1).max(10).required('Custom tags is required'),
  rights: Yup.string().required('Rights is required'),
  clientAsset: Yup.string().when('rights', {
    is: 'Client Assets',
    then: () => Yup.string().required('Client Assets is required.')
  })
});
const CreateAsset = () => {
  const {
    utilityInfo: { openCreateAsset, assetType },
    assetsInfo: { contentSet, adminKeyTags, filters }
  } = useSelector((state) => state);
  const sortAdminkeyTags = adminKeyTags.sort();
  const [adminTags, setAdminTags] = useState([]);
  const [courseContent, setCourseContent] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [fileDetail, setFileDetail] = useState(null);
  const [fieldChange, setFieldChange] = useState(true);
  const [courseCategory, setCourseCategory] = useState([]);
  const [contentText, setContentText] = useState(null);
  const [reset, setReset] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(assetAction.getContentSet());
  }, []);
  useEffect(() => {
    if (formRef?.current?.values?.fileId) {
      setFieldChange(false);
    }
  }, [formRef?.current?.values]);

  const handleDrawer = () => {
    dispatch(utilityAction.openCreateAsset(!openCreateAsset));
    const { initialValues, resetForm } = formRef.current;
    formRef.current.setValues(initialValues);
    resetForm();
    setCustomTags([]);
    setAdminTags([]);
    setCourseContent([]);
    setCourseCategory([]);
    setReset(true);
  };
  const handleCloseAdminTags = (index) => {
    const removedTags = adminTags.filter((tag, i) => i !== index);
    setAdminTags(removedTags);
  };

  const handleCloseCourseCategory = (index) => {
    const removedcourseCategory = courseCategory.filter((course, i) => {
      return i !== index;
    });
    setCourseCategory(removedcourseCategory);
  };
  const handleCloseContentSet = (index) => {
    const removedcontentSet = courseContent.filter((course, i) => i !== index);
    setCourseContent(removedcontentSet);
  };

  const handleCloseCustomTags = (index) => {
    const removedTags = customTags.filter((tag, i) => i !== index);
    setCustomTags(removedTags);
  };
  const selectedCourseId = courseCategory.map(
    (item) => COURSES_CATEGORIES.indexOf(item) + 1
  );
  const eventHandlers = (value) => {
    setContentText(value);
  };
  return (
    <AppDrawer
      showDrawer={openCreateAsset}
      setShowDrawer={handleDrawer}
      headertext="Add New Asset"
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          assetTitle: '',
          adminTags: [],
          customTags: [],
          // contentSet: '',
          courseContent: [],
          courseCategory: [],
          rights: 'CE created',
          clientAsset: '',
          filePath: '',
          fileId: undefined
        }}
        validationSchema={assetValidation}
        onSubmit={async (values, { setFieldValue, setSubmitting }) => {
          try {
            setSubmitting(true);
            const adminTagsValues = values.adminTags.concat(
              values.courseCategory
            );
            let assetRequest = {
              rtype: assetTypeId[fileDetail?.fileType],
              name: values.assetTitle,
              path: values.filePath,
              cids: [...values.courseContent],
              tags: {
                admin: [...new Set(adminTagsValues)],
                custom: customTags
              },
              rights: [values.rights],
              info: {
                resolution: fileDetail?.duration || 0,
                dimention: {
                  w: fileDetail?.width,
                  h: fileDetail?.height
                },
                size: fileDetail?.size
              },
              id: values.fileId,
              courseCategory,
              courseContent
            };
            if (assetType === 2) {
              assetRequest = {
                ...assetRequest,
                blbcontent: { contentText }
              };
            }
            const createAssetRes = await dispatch(
              assetAction.updateAsset(assetRequest)
            );
            if (createAssetRes) {
              setFieldValue('fileId', createAssetRes.data.id);
              // dispatch(
              //   assetAction.filterAssetList(assetTypeId[fileDetail?.fileType])
              // );
              dispatch(assetAction.advanceFilter(filters));
              handleDrawer();
            }
          } catch (error) {
            messageError('Something went wrong');
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          validateForm,
          setFieldValue,
          isSubmitting
        }) => (
          <>
            <div className="offcanvas-body">
              <Form>
                <div className="add-new-asset-form">
                  <div className="row mt-10">
                    <div className="col-12 col-md-6 col-lg-5">
                      <div className="mb-30 custom-from-group">
                        <AppInput
                          title="Title"
                          id="asset-title"
                          placeholder="Title"
                          label="Title"
                          name="assetTitle"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-30 custom-from-group">
                        <AppFileInput
                          setFileDetail={setFileDetail}
                          formRef={formRef}
                          validateForm={validateForm}
                          formEvents={{ touched, errors }}
                          setFieldValue={setFieldValue}
                          setFieldChange={setFieldChange}
                          setCustomTags={setCustomTags}
                          customTags={customTags}
                          fileId={values.fileId}
                          reset={reset}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-7 pl-50">
                      <AppInputGroup
                        id="adminTags"
                        label="Admin Tags"
                        placeholder="Search tags"
                        button="ADD"
                        name="adminTags"
                        options={sortAdminkeyTags}
                        reset={reset}
                        handleClick={(value) => {
                          const adminTagValue = [
                            ...new Set([...adminTags, ...value])
                          ];
                          setAdminTags(adminTagValue);
                          setFieldValue('adminTags', adminTagValue);
                        }}
                      />
                      <AppTags
                        tags={adminTags}
                        closable
                        handleClick={(index) => handleCloseAdminTags(index)}
                      />
                      <AppInputGroup
                        id="customTags"
                        label="Custom Tags"
                        placeholder="Search tags"
                        button="ADD"
                        classes="mt-30"
                        name="customTags"
                        reset={reset}
                        hasText
                        handleClick={(value) => {
                          const customTagValue = [
                            ...new Set([...customTags, value])
                          ];
                          // if (customTagValue.length <= 10) {
                          setCustomTags(customTagValue);
                          setFieldValue('customTags', customTagValue);
                          // }
                        }}
                      />
                      <AppTags
                        tags={customTags}
                        closable
                        handleClick={(index) => handleCloseCustomTags(index)}
                      />
                      <AppInputGroup
                        id="courseCategory"
                        label="Course Category"
                        placeholder="Select Course Category"
                        button="ADD"
                        classes="mt-30"
                        name="courseCategory"
                        options={COURSES_CATEGORIES}
                        reset={reset}
                        handleClick={(value) => {
                          const courseCategoryValue = [
                            ...new Set([...courseCategory, ...value])
                          ];
                          setCourseCategory(courseCategoryValue);
                          setFieldValue('courseCategory', courseCategoryValue);
                        }}
                      />
                      <AppTags
                        tags={courseCategory}
                        closable
                        handleClick={(index) =>
                          handleCloseCourseCategory(index)
                        }
                      />
                      <AppInputGroup
                        id="courseContent"
                        label="Module Title"
                        placeholder="Search Module Title"
                        button="ADD"
                        classes="mt-30"
                        name="courseContent"
                        options={contentSet
                          .filter((item) =>
                            selectedCourseId.includes(item.coursecategory)
                          )
                          .map((item) => ({
                            id: item.id,
                            label: item.coursename
                          }))}
                        reset={reset}
                        handleClick={(value) => {
                          const contentSetValue = [
                            ...new Set([...values.courseContent, value[0].id])
                          ];
                          const contentSetLabel = [
                            ...new Set([...courseContent, value[0].label])
                          ];
                          setCourseContent(contentSetLabel);
                          setFieldValue('courseContent', contentSetValue);
                        }}
                      />

                      <AppTags
                        tags={courseContent}
                        closable
                        handleClick={(index) => handleCloseContentSet(index)}
                      />

                      {/* <div className="custom-from-group mt-30">
                        <label htmlFor="contentSet" className="form-label">
                          Content Set
                        </label>
                        <select
                          className="form-select"
                          aria-label="contentSet"
                          name="contentSet"
                          onChange={handleChange}
                          value={values.contentSet}
                          disabled={!courseCategory}
                        >
                          <option selected>Select Course</option>
                          {contentSet
                            ?.filter(
                              (item) =>
                                item.coursecategory.toString() ===
                                courseCategory.toString()
                            )
                            .map((content) => (
                              <option key={content.id} value={content.id}>
                                {content.coursename}
                              </option>
                            ))}
                        </select>
                        {touched?.contentSet && errors.contentSet ? (
                          <div className="error">{errors.contentSet}</div>
                        ) : null}
                      </div> */}

                      <div className="mt-30">
                        <label className="form-label text-gray-color2">
                          Rights
                        </label>
                        <div>
                          <AppRadio
                            name="rights"
                            label="CE Created"
                            value="CE created"
                            onChange={handleChange}
                            values={values}
                          />
                        </div>
                        <div className="mt-20">
                          <AppRadio
                            name="rights"
                            label="123rf"
                            classes="mr-30"
                            value="123rf"
                            onChange={handleChange}
                          />
                          <AppRadio
                            name="rights"
                            label="Adobe Stock"
                            classes="mr-30"
                            value="Adobe Stock"
                            onChange={handleChange}
                          />
                          <AppRadio
                            name="rights"
                            label="Pond5"
                            classes="mr-30"
                            value="Pond5"
                            onChange={handleChange}
                          />
                          <AppRadio
                            name="rights"
                            label="Others"
                            value="Others"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="d-flex align-items-start mt-20 mb-30">
                          <AppRadio
                            name="rights"
                            label="Client Assets"
                            classes="mr-30 mt-1"
                            value="Client Assets"
                            onChange={handleChange}
                          />
                          <div className="w-100">
                            <AppInput
                              placeholder="Type here.."
                              name="clientAsset"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {touched?.rights && errors.rights ? (
                          <div className="error">{errors.rights}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {assetType === 2 && (
                    <>
                      <label className="form-label text-gray-color2">
                        Content Text
                      </label>
                      <RichTextEditor
                        eventHandler={(e) => eventHandlers(e)}
                        height={150}
                      />
                    </>
                  )}
                </div>
              </Form>
            </div>
            <div className="offcanvas-footer">
              <button
                type="button"
                className="btn btn-outline-primary mr-30"
                onClick={handleDrawer}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={fieldChange}
                loading={isSubmitting}
              >
                SAVE
              </button>
            </div>
          </>
        )}
      </Formik>
    </AppDrawer>
  );
};
export default CreateAsset;
