import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { node } from 'prop-types';
import Header from '../../components/AppHeader/Header';
import SideBar from '../../components/AppSidebar/Sidebar';
import './AppLayout.scss';

function Layout({ children }) {
  const location = useLocation();
  const [classes, setClasses] = useState('');

  useEffect(() => {
    setClasses('');
    if (location.pathname.includes('wishlist')) {
      setClasses('wishlist-page');
    }
  }, [location.pathname]);

  return (
    <div className={`ce-assets-manager ${classes}`}>
      <Header />
      <SideBar />
      <div className="container-fluid ce-assets-container">
        <div className="row">{children}</div>
      </div>
    </div>
  );
}
Layout.propTypes = {
  children: node.isRequired
};
export default Layout;
