import { string, bool, shape } from 'prop-types';
import React, { Fragment } from 'react';
import AddWishList from '../../containers/AssetDetail/AddWishlist';
import { copyText, embedData } from '../../utils/helper';
import { messageSuccess } from '../AppToaster';
import IconButton from './IconButton';

const assetDataText = ['images', 'videos', 'audios', 'topicVideos', 'gif'];
const IconButtonGroup = ({ id, hasWishlist, data, tabVideo }) => {
  const handleSaveInLocal = () => {
    window.open(data.path, '_self', 'top=500,left=500,width=400,height=400');
  };
  const handleEmbed = () => {
    const embedCode = embedData(data);
    copyText(embedCode);
    messageSuccess('Embed Code copied successfully');
  };
  const handleCopyLink = () => {
    const assetURL = `${window.location.origin}/${
      assetDataText[data.rtype - 1]
    }/${id}`;
    copyText(assetURL);
    messageSuccess('Link has copied successfully');
  };
  const handleCopyId = () => {
    copyText(id);
    messageSuccess(`${id} copied successfully`);
  };
  let buttonData = [
    {
      icon: 'content-copy-icon',
      onClick: handleCopyId,
      id
    },
    {
      name: 'Save in local',
      icon: 'browser-updated-icon',
      onClick: handleSaveInLocal
    },
    {
      name: 'Embed',
      icon: 'embed-icon',
      onClick: handleEmbed
    },
    {
      name: 'Copy link',
      icon: 'copy-link-icon',
      onClick: handleCopyLink
    }
  ];

  if (tabVideo) buttonData = buttonData.splice(0, 1);

  if (hasWishlist) {
    buttonData.push({
      template: (
        <AddWishList
          id={id}
          type={tabVideo ? 4 : data?.rtype || 1}
          data={data}
        />
      )
    });
  }
  return (
    <>
      {buttonData.map((btn, i) =>
        btn.template ? (
          <Fragment key={i}>{btn.template}</Fragment>
        ) : (
          <IconButton
            key={i}
            hasId={btn.id}
            name={btn.name}
            icon={btn.icon}
            handleClick={btn.onClick}
          />
        )
      )}
    </>
  );
};

IconButtonGroup.defaultProps = {
  id: '',
  hasWishlist: false,
  data: null,
  tabVideo: false
};
IconButtonGroup.propTypes = {
  id: string,
  hasWishlist: bool,
  data: shape(),
  tabVideo: bool
};

export default IconButtonGroup;
