import React, { useEffect, useState } from 'react';
import { string, bool } from 'prop-types';
import { messageSuccess } from '../AppToaster';
import { truncateText } from '../../utils/helper';

const AppCopyLabel = ({ value, truncate, noCopy }) => {
  const [text, setText] = useState('');
  useEffect(() => {
    if (truncate) {
      const truncatedText = truncateText(value || '');
      setText(truncatedText);
    } else setText(value);
  }, [value, truncate]);
  const handleCopy = async () => {
    if (!noCopy) {
      await navigator.clipboard.writeText(value);
      messageSuccess(`${value} copied successfully`);
    }
  };

  return (
    <span className="pointer" onClick={handleCopy}>
      {text}
    </span>
  );
};

AppCopyLabel.defaultProps = {
  truncate: false,
  noCopy: false
};
AppCopyLabel.propTypes = {
  value: string.isRequired,
  truncate: bool,
  noCopy: bool
};

export default AppCopyLabel;
