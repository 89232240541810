/* eslint-disable no-unused-vars */
import { bool, shape, string } from 'prop-types';
import React from 'react';
import AudioPlayer from './AudioPlayer';
import ImageComponent from './ImageComponent';
import VideoPlayer from './VideoPlayer';

// eslint-disable-next-line react/prop-types
const AssetPlayers = ({ rtype, path, name, info, controls, view, id }) => {
  if (rtype === 1 || rtype === 5)
    return (
      <ImageComponent
        path={path}
        name={name}
        info={info}
        view={view}
        imgId={id}
      />
    );
  if (Number(rtype) === 2)
    return <VideoPlayer path={path} info={info} controls={controls} />;
  if (rtype === 3)
    return <AudioPlayer path={path} info={info} controls={controls} />;
  return null;
};

AssetPlayers.defaultProps = {
  controls: false
};
AssetPlayers.propTypes = {
  rtype: string.isRequired,
  path: string.isRequired,
  view: string.isRequired,
  name: string.isRequired,
  controls: bool,
  info: shape().isRequired
};

export default AssetPlayers;
