import React from 'react';
import './header.scss';
import { useNavigate } from 'react-router-dom';
import Dehaze from '@material-ui/icons/Dehaze';
import { useDispatch, useSelector } from 'react-redux';
import HomeIcon from '@material-ui/icons//Home';
import * as utilityInfoAction from '../../redux/actions/utilityInfoAction';
import AppLogo from '../AppLogo/AppLogo';

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const utilityInfo = useSelector((state) => state.utilityInfo);

  const toggleSideNav = () => {
    dispatch(utilityInfoAction.toggleSideBar(!utilityInfo?.isOpen));
  };

  const handleRedirect = () => {
    dispatch(utilityInfoAction.updateAssetType(1));
    navigate('/');
  };

  return (
    <header className="ce-assets-manager-header">
      <nav>
        <div className="nav-flex-items">
          <ul className="ml-n-10">
            <li>
              <Dehaze onClick={() => toggleSideNav()} />
            </li>
            <li>
              <h4 className="ce-asset-hero-head">CE Assets</h4>
            </li>
            {window?.location?.pathname === '/' ? null : (
              <HomeIcon
                style={{ marginLeft: '10px' }}
                onClick={handleRedirect}
              />
            )}
          </ul>
          <AppLogo logoStyle={{ width: '180px' }} onClick={handleRedirect} />
        </div>
      </nav>
    </header>
  );
}

export default Header;
