/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { string } from 'prop-types';
import { useField } from 'formik';

const AppInput = (props) => {
  const { id, placeholder, type, title } = props;
  const [field, meta] = useField(props);
  return (
    <>
      {title && (
        <label htmlFor={id} className="form-label">
          {title}
        </label>
      )}
      <input
        type={type}
        className="form-control"
        id={id}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
AppInput.defaultProps = {
  id: 'id',
  placeholder: 'placeholder',
  type: 'text',
  title: ''
};
AppInput.propTypes = {
  id: string,
  placeholder: string,
  type: string,
  title: string
};

export default AppInput;
