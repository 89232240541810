/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import * as types from '../reducers/types';
import apiRequests from '../../services/axiosService';
import { messageError, messageSuccess } from '../../components/AppToaster';
// import { flattenTags } from '../../utils/helper';

export const getWishListData = async (userId) => {
  const wishList = await apiRequests({
    method: 'get',
    url: `assets/wishlist/${userId}`
  });
  if (wishList.error) {
    messageError('Error in getting wishlist');
  }
  if (!wishList?.data?.result) return { data: [] };
  return wishList.data || { data: [] };
};

export const checkDuplicateFolders = (folders, name) => {
  return folders?.findIndex((folder) => folder.name === name) >= 0;
};
export const updateFolders = (folders, id = '', detail = false) => {
  return async (dispatch, getState) => {
    try {
      if (folders) {
        const allFolders = getState().wishlistInfo.folders || [];
        folders.map((folder) => {
          const getIndex = allFolders.findIndex((allFolder) => {
            return allFolder.id === folder.id;
          });
          if (id !== '') {
            folder.active = false;
            if (id === folder.id) folder.active = true;
          }
          if (getIndex < 0) {
            if (detail) allFolders.unshift(folder);
            else allFolders.push(folder);
          } else allFolders[getIndex] = folder;
          return folder;
        });
        dispatch({
          type: types.UPDATE_WISHLIST_FOLDER,
          payload: [...allFolders]
        });
      } else {
        dispatch({
          type: types.UPDATE_WISHLIST_FOLDER,
          payload: null
        });
      }
    } catch (error) {
      messageError('Error in updating wishlist folders');
    }
  };
};
export const filterWishList = (id) => {
  return async (dispatch, getState) => {
    try {
      const allList = getState().wishlistInfo.wishlist;
      const filterList = allList.filter((list) => list._id === id);
      dispatch({
        type: types.FILTER_WISHLIST,
        payload: filterList[0] || null
      });
    } catch (error) {
      messageError('Error in filter wishlist');
    }
  };
};
export const updateFoldersStatus = (id) => {
  return async (dispatch, getState) => {
    try {
      const allFolders = getState().wishlistInfo.folders;
      allFolders.map((folder) => {
        folder.active = false;
        if (folder.id === id) folder.active = true;
        return folder;
      });
      dispatch({
        type: types.UPDATE_WISHLIST_FOLDER,
        payload: [...allFolders]
      });
    } catch (error) {
      messageError('Error in updating wishlist folder status');
    }
  };
};

export const formatFolder = async (wishListData, id = '') => {
  try {
    const getFolders = await wishListData.map((wishlist) => {
      const findActiveWishList = wishlist.list.findIndex(
        (list) => list._id === id
      );
      return {
        name: wishlist.name,
        active: findActiveWishList >= 0,
        id: wishlist._id
      };
    });
    if (id === '') {
      getFolders[0].active = true;
    }
    return getFolders;
  } catch (error) {
    return null;
  }
};
export const updateWishListAsset = (id = '') => {
  return async (dispatch, getState) => {
    try {
      const wishListData = getState().wishlistInfo.wishlist;
      const getFolders = await wishListData.map((wishlist) => {
        const findActiveWishList = wishlist.list.findIndex(
          (list) => list._id === id
        );
        return {
          name: wishlist.name,
          active: findActiveWishList >= 0,
          id: wishlist._id
        };
      });
      if (id === '') {
        getFolders[0].active = true;
      }
      dispatch({
        type: types.UPDATE_WISHLIST_FOLDER,
        payload: [...getFolders]
      });
      return getFolders;
    } catch (error) {
      return null;
    }
  };
};
export const addWishList = (body) => {
  return async (dispatch, getState) => {
    try {
      const userInfo = getState().userInfo.user;
      const allFolders = getState().wishlistInfo.folders;
      const wishlistData = getState().wishlistInfo.wishlist;
      let assets = getState().assetsInfo?.allAsset[body?.type];
      const isLGV = body?.type === 4;
      if (isLGV) {
        assets = body?.lgData;
        delete body?.lgData;
      }
      const hasFolder = await checkDuplicateFolders(allFolders, body.name);
      if (!hasFolder) {
        let ubody = {
          ...body,
          uid: userInfo._id
        };
        if (ubody.list) ubody.list = ubody.list.filter((list) => list);
        if (isLGV) {
          ubody = { ...ubody, desc: { list: ubody?.list }, list: [] };
        }
        const wishList = await apiRequests({
          method: 'post',
          url: `assets/createwishlist`,
          body: ubody
        });
        let wishLists = [];
        if (isLGV) wishLists = [...wishLists, assets];
        else {
          const getAsset =
            assets?.filter((asset) => asset?.id === body?.list[0])[0] || {};
          wishLists.push(getAsset);
        }
        wishList.data.data.list = wishLists;
        dispatch({
          type: types.ADD_WISHLIST,
          payload: [...wishlistData, wishList.data.data]
        });
        return wishList.data.data;
      }
      messageError('Folder name already exists');
      return null;
    } catch (error) {
      messageError('Error in adding asset in wishlist');
      return null;
    }
  };
};
export const updateWishListPage = (body) => {
  return async (dispatch, getState) => {
    try {
      const wishListData = getState().wishlistInfo.wishlist;
      const updatedListIndex = wishListData.findIndex(
        (wish) => wish._id === body._id
      );
      let uniqueList = new Set([...body.list]);
      uniqueList = [...uniqueList].filter((item) => item);
      let uniqueLgList = new Set([...body.desc.list]);
      uniqueLgList = [...uniqueLgList].filter((item) => item);
      const ubody = {
        ...body,
        list: [...uniqueList],
        desc: { list: [...uniqueLgList] }
      };
      const wishList = await apiRequests({
        method: 'post',
        url: `assets/updateWishlist`,
        body: ubody
      });
      if (wishList.data.status === 'success') {
        const wishLists = wishListData[updatedListIndex].list.filter(
          (wishAsset) =>
            body.list.includes(wishAsset._id) ||
            body?.desc?.list?.includes(wishAsset._id || wishAsset.id)
        );
        // ubody.list.map((list) => {
        //   const getAsset = assets.filter((asset) => asset.id === list)[0];
        //   if (getAsset) wishLists.push(getAsset);
        //   return getAsset;
        // });
        ubody.list = wishLists;
        dispatch({
          type: types.UPDATE_WISHLIST,
          payload: {
            index: updatedListIndex,
            list: ubody.list,
            notes: ubody.disc,
            name: ubody.name,
            desc: ubody?.desc || {}
          }
        });
        const folderUpdate = {
          name: ubody.name,
          active: true,
          id: ubody._id
        };
        dispatch(updateFolders([folderUpdate]));
        messageSuccess('Wishlist updated successfully');
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      messageError('Error in updating wishlist');
      return false;
    }
  };
};
export const updateWishListData = (body) => {
  return async (dispatch, getState) => {
    try {
      const wishListData = getState().wishlistInfo.wishlist;
      const allFolders = getState().wishlistInfo.folders;
      const assets = getState().assetsInfo.allAsset[body.type];
      const isLGV = body?.type === 4;
      const lgAssets = body?.lgData || {};
      delete body.lgData;
      const folderIndex = body.index;
      allFolders[folderIndex] = {
        ...allFolders[folderIndex],
        loading: true
      };
      dispatch({
        type: types.ADD_WISHLIST_FOLDERS,
        payload: [...allFolders]
      });
      const updatedListIndex = wishListData.findIndex(
        (wish) => wish._id === body._id
      );
      const assetsList = wishListData[updatedListIndex].list
        ?.filter((list) => {
          return list.rtype !== 4;
        })
        .map((list) => list?._id);
      const legacyList = wishListData[updatedListIndex]?.desc?.list || [];
      let uniqueList = new Set([...(assetsList || []), ...body.list]);
      if (body.active) {
        uniqueList.delete(body.list[0]);
      }
      uniqueList = [...uniqueList].filter((item) => item);
      let ubody = {
        ...body
      };
      if (isLGV) {
        uniqueList = new Set([...(legacyList || []), ...body.list]);
        let uniqueAssetsList = new Set([...assetsList]);
        uniqueAssetsList = [...uniqueAssetsList].filter((item) => item);
        ubody = {
          ...ubody,
          desc: { list: [...uniqueList] },
          list: [...uniqueAssetsList]
        };
      } else {
        let uniqueLgAssetsList = new Set([...legacyList]);
        uniqueLgAssetsList = [...uniqueLgAssetsList].filter((item) => item);
        ubody = {
          ...ubody,
          list: [...uniqueList],
          desc: { list: [...uniqueLgAssetsList] }
        };
      }
      const wishList = await apiRequests({
        method: 'post',
        url: `assets/updateWishlist`,
        body: ubody
      });
      if (wishList.data.status === 'success') {
        allFolders[folderIndex] = {
          ...allFolders[folderIndex],
          active: !allFolders[folderIndex].active
        };
      }
      allFolders[folderIndex] = {
        ...allFolders[folderIndex],
        loading: false
      };
      dispatch({
        type: types.ADD_WISHLIST_FOLDERS,
        payload: [...allFolders]
      });
      // eslint-disable-next-line no-unsafe-optional-chaining
      let wishLists = [...wishListData[updatedListIndex]?.list];
      if (isLGV) wishLists = [...wishLists, lgAssets];
      else {
        const getAsset =
          assets?.filter((asset) => asset?.id === body?.list[0])[0] || {};
        wishLists.push(getAsset);
      }
      dispatch({
        type: types.UPDATE_WISHLIST,
        payload: {
          index: updatedListIndex,
          list: wishLists,
          desc: ubody.desc
        }
      });
      return null;
    } catch (error) {
      console.log(error);
      messageError('Error in updating wishlist');
      return null;
    }
  };
};
export const updateWishList = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.ADD_WISHLIST,
        payload: data
      });
    } catch (error) {
      console.log(error);
    }
  };
};
