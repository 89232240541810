export const assetTypeId = {
  Image: 1,
  Video: 2,
  Audio: 3,
  Gif: 5,
  Ai: 10
};
export const sampleAdminTags = ['admintags', 'tag2'];
export const sampleCustomTags = ['customtags', 'tag3'];
export const ASSET_TYPES = ['Image', 'Video', 'Audio'];
