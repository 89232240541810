/* eslint-disable no-param-reassign */
import { Field, Formik, Form } from 'formik';
import { bool, func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import backArrowIcon from '../../assets/img/ic_back-arrow.svg';
import * as assetAction from '../../redux/actions/assetsInfoAction';
import * as utilityInfoAction from '../../redux/actions/utilityInfoAction';
import AppTags from '../../components/AppTags/AppTags';
import AppInputGroup from '../../components/AppFormComponents/AppInputGroup';
import AppRadio from '../../components/AppFormComponents/AppRadio';
import AppInput from '../../components/AppFormComponents/AppInput';

const COURSES_CATEGORIES = [
  `Import`,
  `Export`,
  `Sanctions`,
  `Miscellneous`,
  `Security & Trade`,
  `Corruption & Antiboycott`
];

const filterValidation = Yup.object({
  otherTags: Yup.string().when('adminTags', (tags) => {
    return tags.includes('others')
      ? Yup.string().required('Other tags is required')
      : Yup.string();
  }),
  clientAssetRights: Yup.string().when('rights', (rights) => {
    return rights === 'Client Assets'
      ? Yup.string().required('This is required')
      : Yup.string();
  })
});
const getAssetTypes = ['images', 'videos', 'audios', 'topicVideos', 'gif'];
const AdvanceFilters = ({ showFilter, setShowFilter }) => {
  const {
    utilityInfo: { assetType },
    assetsInfo: { filters: filtersInfo, assetTags, adminKeyTags, filteredTags }
  } = useSelector((state) => state);
  const [courseCategory, setCourseCategory] = useState([]);
  const [reset, setReset] = useState(false);
  const [adminTags, setAdminTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [filteredAssetTag, setFilteredAssettag] = useState(filteredTags);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [olderAsset, setolderAsset] = useState('');
  const formRef = useRef();
  const resetForm = () => {
    const assetDetailType = olderAsset || assetType;
    const assetValue = getAssetTypes[assetDetailType - 1];
    const formikContext = formRef.current;
    const formValues = formikContext.initialValues;
    setCourseCategory([]);
    setAdminTags([]);
    formValues.assetTypeFilter = assetValue;
    formikContext.resetForm({ values: formValues });
    const fieldData = {
      ...filtersInfo,
      ...formValues,
      assetTypeFilter: assetDetailType
    };
    dispatch(utilityInfoAction.updateAssetType(assetDetailType));
    navigate(`/${assetValue}`);
    dispatch(assetAction.advanceFilter(fieldData));
    setShowFilter(false);
  };
  useEffect(() => {
    if (adminKeyTags) {
      const tagData = [...new Set(adminKeyTags.concat(assetTags))];
      setAllTags(tagData);
    }
  }, [adminKeyTags]);

  useEffect(() => {
    setFilteredAssettag(filteredTags);
    setAdminTags(filteredTags);
  }, [filteredTags]);

  useEffect(() => {
    const assetValue = getAssetTypes[assetType - 1];
    formRef?.current?.setFieldValue('assetTypeFilter', assetValue);
  }, [assetType]);
  if (!assetType) return null;
  const assetsFilter = getAssetTypes[assetType ? assetType - 1 : 0];

  const handleCloseAdminTags = (index) => {
    const existingTag = adminTags[index];
    const removedTags = adminTags.filter((tag, i) => i !== index);
    const removeFromFilteredTag = filteredAssetTag.filter(
      (fTag) => existingTag !== fTag
    );
    setFilteredAssettag(removeFromFilteredTag);
    setAdminTags(removedTags);
  };
  const handleClearFilter = () => {
    setAdminTags([]);
  };
  const handleCloseCourseCategory = (index) => {
    const removedcourseCategory = courseCategory.filter(
      (course, i) => i !== index
    );
    setCourseCategory(removedcourseCategory);
  };
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        sortBy: 'best',
        assetTypeFilter: assetsFilter,
        // tags: [],
        adminTags: filtersInfo?.adminTags || [],
        otherTags: '',
        courseCategory: [],
        rights: '',
        clientAssetRights: ''
      }}
      validationSchema={filterValidation}
      onSubmit={async (values) => {
        let submitValue = {};
        if (values.rights === 'Client Assets') {
          submitValue = {
            ...values,
            rights: values.clientAssetRights
          };
        } else {
          submitValue = { ...values };
        }
        try {
          const filterassetType =
            getAssetTypes.findIndex(
              (asset) => submitValue.assetTypeFilter === asset
            ) + 1;
          // let adminTagsFilter = values.adminTags.concat(
          //   filtersInfo?.adminTags || []
          // );

          const adminTagsFilter = [
            ...new Set(adminTags.concat(filteredAssetTag))
          ];
          const fieldData = {
            ...filtersInfo,
            // ...values,
            ...submitValue,
            adminTags: adminTagsFilter,
            // ...submitValue,
            assetTypeFilter: filterassetType
          };
          if (!olderAsset) setolderAsset(assetType);
          dispatch(utilityInfoAction.updateAssetType(filterassetType));
          navigate(`/${submitValue.assetTypeFilter}`);
          dispatch(assetAction.advanceFilter(fieldData));
          setShowFilter(false);
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {({ handleChange, setFieldValue, handleSubmit, errors, touched }) => (
        <Form>
          <div className="filter-drawer-wrapper">
            <div
              className={`filter-drawer offcanvas offcanvas-end ${
                showFilter && 'show'
              }`}
              tabIndex="-1"
              id="searchFilter"
              aria-labelledby="searchFilterLabel"
            >
              <div className="offcanvas-header">
                <button
                  type="button"
                  className="unset-all text-reset"
                  aria-label="Close"
                  onClick={() => {
                    setShowFilter(!showFilter, setReset(true));
                  }}
                >
                  <img src={backArrowIcon} alt="" />
                </button>
                <h4 id="searchFilterLabel">Advance Filters</h4>
              </div>
              <div className="offcanvas-body">
                <div className="mb-30">
                  <label className="form-label">Asset Type</label>
                  <div>
                    <label className="custom-radio-container mr-30">
                      Image
                      <Field
                        type="radio"
                        name="assetTypeFilter"
                        value="images"
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="custom-radio-container mr-30">
                      Gif
                      <Field type="radio" name="assetTypeFilter" value="gif" />
                      <span className="checkmark" />
                    </label>
                    <label className="custom-radio-container mr-30">
                      Video
                      <Field
                        type="radio"
                        name="assetTypeFilter"
                        value="videos"
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="custom-radio-container">
                      Audio
                      <Field
                        type="radio"
                        name="assetTypeFilter"
                        value="audios"
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>

                <div className="mb-30">
                  <label className="form-label">Tags</label>

                  <AppInputGroup
                    id="adminTags"
                    label="Keywords"
                    placeholder="Enter keywords"
                    button="ADD"
                    name="adminTags"
                    options={allTags}
                    reset={reset}
                    handleClick={(value) => {
                      const adminTagValue = [
                        ...new Set([...adminTags, ...value])
                      ];
                      setAdminTags(adminTagValue);
                      setFieldValue('adminTags', adminTagValue);
                    }}
                  />
                  <AppTags
                    tags={adminTags}
                    hasClear
                    closable
                    clearFilter={handleClearFilter}
                    handleClick={(index) => handleCloseAdminTags(index)}
                    classes="searchTags"
                  />

                  {/* <div>
                    <label className="custom-checkbox-container mr-30">
                      Export
                      <Field
                        type="checkbox"
                        name="tags"
                        value="export"
                        onChange={handleChange}
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="custom-checkbox-container mr-30">
                      Import
                      <Field
                        type="checkbox"
                        name="tags"
                        value="import"
                        onChange={handleChange}
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="custom-checkbox-container">
                      Transportation
                      <Field
                        type="checkbox"
                        name="tags"
                        value="transportation"
                        onChange={handleChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div> */}
                  {/* <div className="d-flex align-items-start mt-20">
                    <label className="custom-checkbox-container mr-30">
                      Others
                      <Field
                        type="checkbox"
                        name="adminTags"
                        value="others"
                        onChange={handleChange}
                      />
                      <span className="checkmark" />
                    </label>
                    <div>
                      <Field
                        type="text"
                        name="otherTags"
                        className="form-control"
                        placeholder="Type here..."
                        onChange={handleChange}
                      />
                      {touched?.otherTags && errors.otherTags ? (
                        <div className="error">{errors.otherTags}</div>
                      ) : null}
                    </div>
                  </div> */}
                </div>
                {/* <div className="mb-30">
                  <label className="form-label">Course Category</label>
                  <div>
                    {COURSES_CATEGORIES.map((item) => (
                      <label
                        className="custom-checkbox-container mr-30"
                        style={{ marginTop: '10px' }}
                      >
                        {item}
                        <Field
                          type="checkbox"
                          name="courseCategory"
                          value={item}
                          onChange={handleChange}
                        />
                        <span className="checkmark" />
                      </label>
                    ))}
                  </div>
                </div> */}
                {/* <div
                  className="custom-from-group mt-30"
                  key={
                    COURSES_CATEGORIES[courseCategory - 1] || 'courseCategory'
                  }
                >
                  <label htmlFor="courseCategory" className="form-label">
                    Course Category
                  </label>
                  <select
                    className="form-select"
                    aria-label="courseCategory"
                    name="courseCategory"
                    defaultValue={COURSES_CATEGORIES[courseCategory - 1] || ''}
                    onChange={(e) => {
                      setCourseCategory(
                        COURSES_CATEGORIES.indexOf(e.target.value) + 1
                      );
                    }}
                  >
                    <option selected>Select Course Category</option>
                    {COURSES_CATEGORIES?.map((course, index) => (
                      <option key={`${course}${index}`} value={course}>
                        {course}
                      </option>
                    ))}
                  </select>
                  {!courseCategory ? (
                    <div className="error">This field is required</div>
                  ) : null}
                </div> */}
                <AppInputGroup
                  id="courseCategory"
                  label="Course Category"
                  placeholder="Select Course Category"
                  button="ADD"
                  classes="mt-30"
                  name="courseCategory"
                  options={COURSES_CATEGORIES}
                  reset={reset}
                  handleClick={(value) => {
                    const courseCategoryValue = [
                      ...new Set([...courseCategory, ...value])
                    ];
                    setCourseCategory(courseCategoryValue);
                    setFieldValue('courseCategory', courseCategoryValue);
                  }}
                />
                <AppTags
                  tags={courseCategory}
                  closable
                  handleClick={(index) => handleCloseCourseCategory(index)}
                />
                <div>
                  <div className="mt-30">
                    <label className="form-label text-gray-color2">
                      Rights
                    </label>
                    <div className="mt-20">
                      <AppRadio
                        name="rights"
                        label="CE Created"
                        classes="mr-20"
                        value="CE created"
                        onChange={handleChange}
                        // values={values}
                      />
                      <AppRadio
                        name="rights"
                        label="123rf"
                        classes="mr-30"
                        value="123rf"
                        onChange={handleChange}
                      />
                      <AppRadio
                        name="rights"
                        label="Adobe Stock"
                        classes="mr-30"
                        value="Adobe Stock"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <AppRadio
                        name="rights"
                        label="Pond5"
                        classes="mr-50"
                        value="Pond5"
                        onChange={handleChange}
                      />
                      <AppRadio
                        name="rights"
                        label="Others"
                        classes="mt-20"
                        value="Others"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="d-flex align-items-start mt-20 mb-30">
                      <AppRadio
                        name="rights"
                        label="Client Assets"
                        classes="mr-30 mt-1"
                        value="Client Assets"
                        onChange={handleChange}
                      />
                      <div className="w-100">
                        <AppInput
                          placeholder="Type here.."
                          name="clientAssetRights"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {touched?.rights && errors.rights ? (
                      <div className="error">{errors.rights}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="offcanvas-footer">
                <button
                  type="button"
                  className="btn btn-outline mr-30"
                  onClick={resetForm}
                >
                  RESET FILTER
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  APPLY FILTER
                </button>
              </div>
            </div>
            <div className={`filter-drawer-fade ${showFilter && 'show'}`} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

AdvanceFilters.propTypes = {
  showFilter: bool.isRequired,
  setShowFilter: func.isRequired
};
export default AdvanceFilters;
