import * as types from './types';

const initialState = {
  user: null
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USERS:
      return {
        ...state,
        user: action.payload
      };
    case types.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default userInfoReducer;
