/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-extend-native */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import axios from 'axios';
import * as types from '../reducers/types';
import apiRequests from '../../services/axiosService';
import customApiRequests from '../../services/customAxiosService';
import { messageError, messageSuccess } from '../../components/AppToaster';
// eslint-disable-next-line prettier/prettier
import {flattenTags,
  splitMulti,
  timeout,
  // eslint-disable-next-line prettier/prettier
  getMidjPath} from '../../utils/helper';
import { setloader } from './utilityInfoAction';
import { updateWishListAsset } from './wishlistActions';

const filterTags = async (assets) => {
  const allTags = new Set();
  await Object.values(assets).map(async (assetData) => {
    await assetData.map((asset) => {
      asset.tags.admin.map((adminTag) => allTags.add(adminTag));
      asset.tags.custom.map((customTag) => allTags.add(customTag));
      return allTags;
    });
  });
  return [...allTags];
};
export function getTagList() {
  return async (dispatch, getState) => {
    const { adminKeyTags } = getState().assetsInfo;
    let adminTags = [];
    if (adminKeyTags.length > 0) {
      adminTags = adminKeyTags;
    } else {
      const tagList = await customApiRequests({
        method: 'get',
        url: 'https://webapi.contentenablers.com/api/ce-assets-json'
      });
      if (tagList.data) {
        const tagRowData =
          await tagList.data.data.attributes.assetmgr_config.keywords.map(
            (keys) => {
              return keys.tags.map((tag) => {
                adminTags.push(tag);
                return tag;
              });
            }
          );
        dispatch({
          type: types.UPDATE_ADMIN_TAGS,
          payload: adminTags
        });
      } else {
        messageError('Error in getting Tags');
      }
    }
  };
}
// url: 'assets/uploadresource', --- Vibrant
export async function uploadAssetFile(headers, data) {
  try {
    const assetFile = await apiRequests({
      method: 'put',
      url:
        // eslint-disable-next-line prettier/prettier
        (window.location.href.includes('assets.contentenablers.dev') ||
        // eslint-disable-next-line prettier/prettier
          window.location.href.includes('localhost'))
          ? 'assets/staginguploadAssets'
          : 'assets/resourcesupload',
      headers,
      body: data
    });
    if (assetFile.data.data.path.indexOf('/images') !== -1) await timeout(3000);
    return assetFile;
  } catch (error) {
    messageError('Error in uploading asset');
    return null;
  }
}
export function getAssetList(type = undefined) {
  return async (dispatch, getState) => {
    try {
      const { allAsset } = getState().assetsInfo;
      const totalAsset = allAsset
        ? allAsset[1].length + allAsset[2].length + allAsset[3].length
        : 0;
      const { assetTags } = getState().assetsInfo;
      const { activePage } = getState().assetsInfo;
      const { NextPage } = getState().assetsInfo;
      const { size } = getState().assetsInfo;
      const { remainingAssets } = getState().assetsInfo;
      let assetData = {
        1: [],
        2: [],
        3: [],
        5: []
      };
      let allAssetTags = [];
      if (allAsset && remainingAssets === 0) {
        assetData = allAsset;
        allAssetTags = assetTags;
      } else {
        // const assetList = await apiRequests({
        //   method: 'get',
        //   url: 'assets/getlist'
        // });
        if (NextPage === 0) dispatch(setloader(true));
        const hostNames = ['localhost', 'assets.contentenablers.dev'];
        const assetList = await axios({
          method: 'GET',
          url:
            hostNames.indexOf(window.location.hostname) !== -1
              ? `https://tradecompliance.training/xapice/api/assets/paginate/${
                  NextPage || 0
                }/${size}`
              : `https://tradecompliancecourses.com/ceauthor/api/assets/paginate/${
                  NextPage || 0
                }/${size}`
        });
        if (assetList.data) {
          await assetList.data.rows.map((asset) => {
            if (asset?.status !== 1) return;
            assetData[asset.rtype].push(asset);
            // eslint-disable-next-line consistent-return
            return assetData;
          });
        }
        allAssetTags = await filterTags(assetData);
        dispatch({
          type: types.SET_ACTIVE_PAGE,
          payload: {
            next: activePage + 1,
            count: totalAsset + assetList.data.rows.length
          }
        });
        dispatch({
          type: types.SET_NEXT_PAGE,
          payload: {
            next: NextPage + 1,
            size: assetList.data.pg.size,
            count:
              assetList.data.pg.count -
              (totalAsset + assetList.data.rows.length)
          }
        });
      }
      if (allAsset) {
        allAsset[1] = [...allAsset[1], ...assetData[1]];
        allAsset[2] = [...allAsset[2], ...assetData[2]];
        allAsset[3] = [...allAsset[3], ...assetData[3]];
        allAsset[5] = [...allAsset[5], ...assetData[5]];
      }
      dispatch({
        type: types.ALL_ASSETS,
        payload: {
          assets: allAsset || assetData,
          tags: assetTags
            ? [...new Set([...assetTags, ...allAssetTags])]
            : allAssetTags
        }
      });
      if (type) {
        dispatch({
          type: types.FILTERED_ASSETS,
          payload: assetData[type]
        });
      }
      dispatch(setloader(false));
    } catch (error) {
      messageError('Error in getting asset list');
    }
  };
}

export function createAsset(body) {
  return async (dispatch, getState) => {
    try {
      const assetCreate = await apiRequests({
        method: 'post',
        url: 'assets',
        body
      });
      if (assetCreate.data) {
        const responseData = assetCreate.data;
        const { allAsset, assetTags } = getState().assetsInfo;
        const assetTagData = flattenTags(responseData);
        const uniqueData = [...assetTags, ...assetTagData];
        let assetData = allAsset;
        if (assetData) assetData[responseData.rtype].push(responseData);
        else
          assetData = {
            [responseData.rtype]: [responseData]
          };
        dispatch({
          type: types.ALL_ASSETS,
          payload: { assets: assetData, tags: [...new Set(uniqueData)] }
        });
        messageSuccess('Asset created successfully!!');
        return assetCreate;
      }
      messageError('Error in creating asset');
      return null;
    } catch (error) {
      console.log(error);
      messageError('Error in creating asset');
      return null;
    }
  };
}
export function updateAsset(body, hasMessage = true) {
  return async (dispatch, getState) => {
    try {
      const assetCreate = await apiRequests({
        method: 'post',
        url: 'assets/updateAsset',
        body
      });
      if (assetCreate.data) {
        const responseData = body;
        const { allAsset, assetTags } = getState().assetsInfo;
        const assetTagData = flattenTags(responseData);
        const uniqueData = [...assetTags, ...assetTagData];
        const filterData = allAsset[responseData.rtype].findIndex(
          (asset) => asset.id === body.id
        );
        allAsset[responseData.rtype][filterData] = body;
        dispatch({
          type: types.ALL_ASSETS,
          payload: { assets: allAsset, tags: [...new Set(uniqueData)] }
        });
        if (hasMessage) messageSuccess('Asset updated successfully!!');
        return assetCreate;
      }
      messageError('Error in updating asset');
      return null;
    } catch (error) {
      console.log(error);
      messageError('Error in updating asset');
      return null;
    }
  };
}
export function deleteAsset(body, hasMessage = true) {
  // const item = { id: body?.id, status: -1 };
  return async (dispatch, getState) => {
    try {
      const assetCreate = await apiRequests({
        method: 'post',
        url: 'assets/updateAsset',
        body
      });
      if (assetCreate.data) {
        const responseData = body;
        const { allAsset, assetTags } = getState().assetsInfo;
        const assetTagData = flattenTags(responseData);
        const uniqueData = [...assetTags, ...assetTagData];
        // const filterData = allAsset[responseData.rtype].findIndex(
        //   (asset) => asset.id === body.id
        // );
        // allAsset[responseData.rtype][filterData] = body;
        const filteredAsset = allAsset[responseData.rtype].filter(
          (asset, i) => {
            return body.id !== asset.id;
          }
        );
        dispatch({
          type: types.ALL_ASSETS,
          payload: {
            assets: { ...allAsset, [responseData.rtype]: filteredAsset },
            tags: [...new Set(uniqueData)]
          }
        });
        dispatch({
          type: types.FILTERED_ASSETS,
          payload: filteredAsset
        });
        if (hasMessage) messageSuccess('Asset deleted successfully!!');
        return filteredAsset;
      }
      messageError('Error in updating asset');
      return null;
    } catch (error) {
      console.log(error);
      messageError('Error in updating asset');
      return null;
    }
  };
}
export function filterAssetList(type) {
  return async (dispatch, getState) => {
    try {
      const assetFilter = getState().assetsInfo.allAsset;
      if (assetFilter) {
        const filteredAsset = assetFilter[type];
        dispatch({
          type: types.FILTERED_ASSETS,
          payload: filteredAsset
        });
      }
      return null;
    } catch (error) {
      messageError('Error in filter asset list');
      return null;
    }
  };
}
const getSimilarAssets = async (assets, tags, eAsset) => {
  try {
    const filteredArray = new Set();
    if (assets) {
      await tags.map((tag) => {
        return assets.map((asset) => {
          if (
            (asset.tags.admin.includes(tag) ||
              asset.tags.custom.includes(tag)) &&
            asset.id !== eAsset.id &&
            asset.rtype === eAsset.rtype
          )
            filteredArray.add(asset);
          return [...filteredArray];
        });
      });
    }
    return [...filteredArray];
  } catch (error) {
    messageError('Error in getting similar asset');
    return [];
  }
};
export function getAsset(type, id) {
  return async (dispatch, getState) => {
    try {
      const filteredAsset =
        getState().assetsInfo.allAsset[type]?.filter(
          (asset) => asset.id === id
        ) || [];
      const getTags = flattenTags(filteredAsset[0]);
      if (filteredAsset[0]) {
        const similarAssets = await getSimilarAssets(
          getState().assetsInfo.allAsset[type],
          getTags,
          filteredAsset[0]
        );
        filteredAsset[0].similarAsset = similarAssets;
      }

      dispatch({
        type: types.GET_ASSET,
        payload: filteredAsset[0]
      });
    } catch (error) {
      messageError('Error in getting asset');
    }
  };
}
export function resetAsset() {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ASSET,
      payload: null
    });
  };
}

export function getContentSet() {
  return async (dispatch, getState) => {
    try {
      const { contentSet } = getState().assetsInfo;
      if (contentSet.length > 0) {
        dispatch({
          type: types.FETCH_CONTENT_SET,
          payload: contentSet
        });
      } else {
        const contentData = await apiRequests({
          method: 'get',
          url: 'courses'
        });
        if (contentData.data) {
          const contentRowData = contentData.data.rows;
          dispatch({
            type: types.FETCH_CONTENT_SET,
            payload: contentRowData
          });
        }
      }
    } catch (error) {
      messageError('Error in getting asset courses');
    }
  };
}
export function searchAsset(searchText, type) {
  return async (dispatch, getState) => {
    try {
      const { allAsset: allAssets, filters } = getState().assetsInfo;
      let filteredAsset = allAssets[type];
      if (searchText) {
        const searchExpression = new RegExp(searchText, 'ig');
        filteredAsset = filteredAsset.filter(
          (asset) =>
            asset.id.match(searchExpression) ||
            asset.name.match(searchExpression)
        );
      }
      dispatch({
        type: types.SET_FILTERS,
        payload: { ...filters, searchText }
      });
      dispatch({
        type: types.FILTERED_ASSETS,
        payload: filteredAsset
      });
    } catch (error) {
      messageError('Error in search asset');
    }
  };
}
export function filterAssetTags(searchExpression, asset) {
  const filteredTags = asset.tags.admin.filter((tag) =>
    tag.match(searchExpression)
  );
  const filteredCustomTags = asset.tags.custom.filter((tag) =>
    tag.match(searchExpression)
  );
  return filteredTags.length > 0 || filteredCustomTags.length > 0;
}
export function advanceFilter(filters) {
  return async (dispatch, getState) => {
    try {
      const { allAsset: allAssets, filters: filterInfo } =
        getState().assetsInfo;
      const filteredAsset = allAssets ? allAssets[filters.assetTypeFilter] : [];
      let advanceFilters = filteredAsset || [];
      if (filters?.searchText) {
        let searchFilter = [];
        searchFilter = splitMulti(filters.searchText, [',', ' ', ' and ']);
        let filteredData = [];
        searchFilter?.map((assetText) => {
          assetText = assetText.trim();
          if (assetText) {
            const searchExpression = new RegExp(assetText, 'ig');
            const hasData = filteredAsset.filter(
              (asset) =>
                asset.id.match(searchExpression) ||
                asset.name.match(searchExpression) ||
                filterAssetTags(searchExpression, asset)
            );
            if (hasData.length) filteredData = [...filteredData, ...hasData];
            return hasData;
          }
          return null;
        });
        advanceFilters = filteredData;
      }
      if (filters?.adminTags?.length > 0) {
        if (filters?.adminTags?.includes('others')) {
          filters.adminTags.push(filters.otherTags);
        }
        const adminTagsValues = filters?.adminTags.concat(
          filters.courseCategory || []
        );
        filters.adminTags = [...filters.adminTags, ...adminTagsValues];
        filters.adminTags = [...new Set(filters.adminTags)];
        dispatch({
          type: types.SET_FILTERS_TAGS,
          payload: filters.adminTags
        });
        advanceFilters = filters.adminTags.map((tag) => {
          const tagSearch = new RegExp(tag, 'ig');
          const filteredAdvance = advanceFilters.filter((asset) => {
            const assetTags = asset.tags.admin.concat(asset.tags.custom);
            const filteredAssetTags = assetTags.filter((adminTag) =>
              adminTag.match(tagSearch)
            );
            return filteredAssetTags.length > 0;
          });
          return [...filteredAdvance];
        });
        advanceFilters = Array.prototype.concat.apply([], advanceFilters);
        advanceFilters = [...new Set(advanceFilters)];
      } else {
        dispatch({
          type: types.SET_FILTERS_TAGS,
          payload: []
        });
      }
      let tempResult = [];
      if (filters?.rights?.trim().length > 0) {
        tempResult = advanceFilters.filter((item) =>
          item.rights.includes(filters.rights)
        );
      } else {
        tempResult = advanceFilters;
      }
      const result = tempResult.reduce((finalArray, current) => {
        const obj = finalArray.find((item) => item.id === current.id);
        if (obj) {
          return finalArray;
        }
        return finalArray.concat([current]);
      }, []);
      if (filters?.sortBy === 'newest') {
        result.sort((a, b) => {
          const prevTime = new Date(a.updatedAt).getTime();
          const nextTime = new Date(b.updatedAt).getTime();
          return nextTime - prevTime;
        });
      }
      dispatch({
        type: types.SET_FILTERS,
        payload: { ...filters }
      });
      dispatch({
        type: types.FILTERED_ASSETS,
        payload: result
      });
    } catch (error) {
      console.log(error);
      messageError('Error in filtering asset');
    }
  };
}

export function setArchiveCourses() {
  return async (dispatch, getState) => {
    try {
      const { topicVideoSearchList } = getState().assetsInfo;
      if (topicVideoSearchList?.archiveCrs?.length > 0) {
        dispatch({
          type: types.SET_ARCHIVE_COURSES,
          payload: topicVideoSearchList.archiveCrs
        });
      } else {
        const archiveCourses = await apiRequests({
          method: 'get',
          url: 'assets/getArchiveCourses'
        });
        // const archiveCourses = await axios({
        //   method: 'GET',
        //   url: `https://tradecompliance.training/xapice/api/assets/getArchiveCourses`
        // });
        if (archiveCourses.data) {
          const contentRowData = archiveCourses?.data?.data;
          const flatten = [];
          contentRowData?.forEach((item) => {
            flatten[item?.info?.config?.cid] = {
              ...item,
              id: item?.info?.config?.cid,
              label: item?.coursename || 'next'
            };
          });
          dispatch({
            type: types.SET_ARCHIVE_COURSES,
            payload: flatten
          });
        }
      }
    } catch (error) {
      messageError('Error in getting Topic Video courses');
    }
  };
}

export function setSearchContent(payload) {
  return async (dispatch, getState) => {
    try {
      // eslint-disable-next-line no-constant-condition
      let path = `assets/searchByTopicContent/${payload.data}`;
      if (payload.type === 'fileName')
        path = `assets/searchByParam/fileName/${payload.data}`;
      else if (payload.type === 'topicName')
        path = `assets/searchByParam/title/${payload.data}`;

      const searchLists = await apiRequests({
        method: 'get',
        url: `${path}`
      });
      // const searchLists = await axios({
      //   method: 'GET',
      //   url: `https://tradecompliance.training/xapice/api/assets/searchByParam/title/${title}`
      // });
      if (searchLists.data) {
        const searchList = searchLists?.data?.data;
        let shortedList = [];
        if (searchList?.length > 0) {
          shortedList = searchList.sort(function (a, b) {
            const textA = a.title.toUpperCase();
            const textB = b.title.toUpperCase();
            // eslint-disable-next-line no-nested-ternary
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        dispatch({
          type: types.SET_TV_SEARCH_CONTENT,
          payload: shortedList
        });
      }
    } catch (error) {
      messageError('Error in getting Topic Video Topics');
    }
  };
}

export function SelectedTopicVideoCrs(id) {
  return async (dispatch, getState) => {
    try {
      const { selectedTVCrouse } = getState().assetsInfo;
      if (selectedTVCrouse?.selectedList[id]) {
        dispatch({
          type: types.SELECTED_TV_COURSE,
          payload: {
            ...selectedTVCrouse,
            selected: selectedTVCrouse?.selectedList[id]
          }
        });
      } else {
        const archiveCourses = await apiRequests({
          method: 'get',
          url: `assets/searchByCourseId/${id}`
        });
        // const archiveCourses = await axios({
        //   method: 'GET',
        //   url: `https://tradecompliance.training/xapice/api/assets/searchByCourseId/${id}`
        // });
        if (archiveCourses.data) {
          const contentRowData = archiveCourses?.data?.data;
          const lists = selectedTVCrouse.selectedList;
          lists[id] = archiveCourses?.data?.data;
          let shortedList = [];
          if (contentRowData?.length > 0) {
            shortedList = contentRowData.sort(function (a, b) {
              const textA = a.title.toUpperCase();
              const textB = b.title.toUpperCase();
              // eslint-disable-next-line no-nested-ternary
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }
          const result = {
            selectedList: lists,
            selected: shortedList
          };
          dispatch({
            type: types.SELECTED_TV_COURSE,
            payload: result
          });
        }
      }
    } catch (error) {
      messageError('Error in getting Topic Video courses');
    }
  };
}
/**
 * Midjourney
 */
export const sendPrompt = (body) => {
  return async (dispatch, getState) => {
    try {
      const mjAsset = await apiRequests({
        method: 'post',
        url: `${getMidjPath('live')}/imagine`,
        body
      });
      if (mjAsset.data) {
        const midjourneyResp = mjAsset.data;
        dispatch({
          type: types.SET_MIDJOURNEY_DATA,
          payload: midjourneyResp
        });
      }
      console.log(mjAsset);
    } catch (e) {
      console.log(e);
    }
  };
};
export const makevariation = (body) => {
  return async (dispatch, getState) => {
    const processUri = body.option === 'V' ? '/makevariation' : '/doupscale';
    try {
      const mjAsset = await apiRequests({
        method: 'post',
        url: `${getMidjPath('live')}${processUri}`,
        body: body.payload
      });
      if (mjAsset.data) {
        const midjourneyResp = mjAsset.data;
        dispatch({
          type: types.SET_MIDJOURNEY_DATA,
          payload: midjourneyResp
        });
      }
      console.log(mjAsset);
    } catch (e) {
      console.log(e);
    }
  };
};
export function resetImageGeneration() {
  return async (dispatch, getState) => {
    const mjAsset = await apiRequests({
      method: 'post',
      url: `http://localhost:3000/api/resources/closemdconnection`,
      body: body.payload
    });

    dispatch({
      type: types.RESET_MIDJOURNEY_DATA,
      payload: null
    });
  };
}
