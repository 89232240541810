/* eslint-disable no-unused-vars */
import { node } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../../components/AppHeader/Header';
import SideBar from '../../components/AppSidebar/Sidebar';
import Toolbar from '../../components/AppToolbar/Toolbar';
import * as assetsInfoAction from '../../redux/actions/assetsInfoAction';
import * as utilityInfoAction from '../../redux/actions/utilityInfoAction';
import CreateAsset from '../CreateAssset';
import TopicTolbar from '../../components/AppToolbar/TopicToolbar';

const AssetLayout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [classes, setClasses] = useState('');
  const {
    assetsInfo: { allAsset }
  } = useSelector((state) => state);

  useEffect(() => {
    let assetState = 1;
    if (location.pathname.includes('videos')) assetState = 2;
    if (location.pathname.includes('audios')) assetState = 3;
    if (location.pathname.includes('topicVideos')) assetState = 4;
    if (location.pathname.includes('gif')) assetState = 5;
    dispatch(utilityInfoAction.updateAssetType(assetState));
    if (!allAsset) dispatch(assetsInfoAction.getAssetList(assetState));
  }, [allAsset]);

  useEffect(() => {
    setClasses('');
    if (location.pathname.includes('wishlist')) {
      setClasses('wishlist-page');
    }
  }, [location.pathname]);

  return (
    <div className={`ce-assets-manager ${classes}`}>
      <Header />
      <SideBar />
      <div className="container-fluid ce-assets-container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            {location.pathname.includes('topicVideos') ? (
              <TopicTolbar />
            ) : (
              <Toolbar />
            )}
            {children}
          </div>
        </div>
      </div>
      {!location.pathname.includes('topicVideos') && <CreateAsset />}
    </div>
  );
};
AssetLayout.propTypes = {
  children: node.isRequired
};

export default AssetLayout;
