/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './toolbar.scss';
import FolderSpecialOutlinedIcon from '@material-ui/icons/FolderSpecialOutlined';
// import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Apps from '@material-ui/icons/Apps';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';

import addCircleIcon from '../../assets/img/ic_add-circle.svg';
// import searchIcon from '../../assets/img/ic_search.svg';
import filterIcon from '../../assets/img/ic_filter.svg';
import sortIcon from '../../assets/img/sort-svg.svg';
import dateIcon from '../../assets/img/date-icon.svg';
import * as utilityInfoAction from '../../redux/actions/utilityInfoAction';
import * as assetAction from '../../redux/actions/assetsInfoAction';
import AppButton from '../AppButtons/AppButton';
import AppTags from '../AppTags/AppTags';
import AdvanceFilters from '../../containers/AdvancedFilter';
import useDebounce from '../AppHooks/useDebounce';
// import TopicToolbar from './TopicToolbar';
// import topicVideoIcon from '../../assets/img/ic_topic-videos.svg';
import NavigationButtons from './NavigationButtons';

function Toolbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    utilityInfo: { assetType, isCardView },
    assetsInfo: { filteredAsset, assetTags, filters: filtersInfo, filteredTags }
  } = useSelector((state) => state);
  const [showFilter, setShowFilter] = useState(false);
  const [sort, setSort] = useState(true);
  const [dateSort, setDateSort] = useState(true);
  const [hasClear, setHasClear] = useState(false);
  const [updatedAdminTag, setUpdatedAdminTags] = useState([]);
  const [searchValue, setSearchValue] = useState(filtersInfo?.searchText || '');
  const searchAsset = useDebounce(searchValue, 1000);
  const handleToggleView = (view) =>
    dispatch(utilityInfoAction.toggleGridView(view === 'grid'));

  const handleWishListRedirection = () => {
    navigate('/wishlist');
  };
  const handleSort = (isSort) => {
    setSort(!sort);
    filteredAsset.sort(function (a, b) {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      // eslint-disable-next-line no-nested-ternary
      if (isSort) return textA < textB ? -1 : textA > textB ? 1 : 0;
      // eslint-disable-next-line no-nested-ternary
      return textA < textB ? (textA > textB ? 1 : 0) : -1;
    });
    dispatch(utilityInfoAction.updateAssetType(filteredAsset));
    dispatch(utilityInfoAction.updateAssetType(assetType));
  };
  const handleDateSort = (isSort) => {
    setDateSort(!dateSort);
    filteredAsset.sort(function (a, b) {
      if (isSort) return new Date(b.createdAt) - new Date(a.createdAt);
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    dispatch(utilityInfoAction.updateAssetType(filteredAsset));
    dispatch(utilityInfoAction.updateAssetType(assetType));
  };
  const handleNewAsset = () => {
    dispatch(utilityInfoAction.openCreateAsset(true));
  };
  useEffect(() => {
    const filters = {
      ...filtersInfo,
      searchText: searchValue,
      assetTypeFilter: assetType
    };
    dispatch(assetAction.advanceFilter(filters));
  }, [searchAsset]);

  // useEffect(() => {
  //   setUpdatedAdminTags(assetTags);
  // }, [assetTags]);
  useEffect(() => {
    setHasClear(false);
    const updatedTags = assetTags.filter((tag) => !filteredTags.includes(tag));
    if (filteredTags.length > 0) setHasClear(true);
    setUpdatedAdminTags(updatedTags);
  }, [filteredTags]);

  const handletagRoute = (index, isDelete = false) => {
    setHasClear(true);
    const filters = {
      assetTypeFilter: assetType
    };
    if (isDelete)
      filters.adminTags = filteredTags.filter((tag, i) => i !== index);
    else filters.adminTags = [...filteredTags, updatedAdminTag[index]];
    if (filters.adminTags.length === 0) setHasClear(false);
    dispatch(assetAction.advanceFilter(filters));
  };
  const handleClearFilter = () => {
    setHasClear(false);
    const filters = {
      ...filtersInfo,
      adminTags: null,
      assetTypeFilter: assetType
    };
    dispatch(assetAction.advanceFilter(filters));
  };

  return (
    <div>
      <div className="tool-bar">
        {/* Toolbar Top links */}
        <div className="row">
          <div className="col-6 col-lg-6 mb-30">
            <h4 className="d-inline-flex align-items-center fw-700">
              Create New Asset
              <AppButton
                classes="unset-all ml-20"
                onlyIcon
                icon={addCircleIcon}
                handleClick={handleNewAsset}
                iconBefore
              />
            </h4>
          </div>

          <div className="col-6 col-lg-6 text-end">
            <button
              type="button"
              className="unset-all d-inline-flex align-items-center"
              onClick={handleWishListRedirection}
            >
              <FolderSpecialOutlinedIcon />
              <span className="pl-10 fw-500">My Wishlist</span>
            </button>
          </div>
        </div>

        {/* Searchbox, Tabs and Grid & Listview btns */}
        <div className="row">
          <div className="col-12 col-md-12 d-flex align-items-start mb-10">
            <div className="toolbar-searchbox-wrapper">
              <div className="toolbar-searchbox">
                <input
                  type="text"
                  id="search-box"
                  placeholder="Search by ID, Name, Tags"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />

                {/* Filter Button */}
                <div className="filter-btn">
                  <button
                    type="button"
                    aria-controls="searchFilter"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <img className="mr-10" src={filterIcon} alt="" />
                    Filters
                  </button>
                </div>
                <div className="filter-btn">
                  <button
                    type="button"
                    aria-controls="sortAlpha"
                    onClick={() => handleSort(sort)}
                    className="sort-btn"
                  >
                    <img className="mr-10" width="15" src={sortIcon} alt="" />
                    {sort ? 'A-Z' : 'Z-A'}
                  </button>
                </div>
                <div className="filter-btn">
                  <button
                    type="button"
                    aria-controls="sortAlpha"
                    onClick={() => handleDateSort(dateSort)}
                    className="sort-btn"
                  >
                    <img className="mr-10" width="15" src={dateIcon} alt="" />
                    {sort ? 'Date' : 'Date'}
                  </button>
                </div>
              </div>
            </div>

            {/* Tabs */}
            <NavigationButtons />
            {/* Tabs */}

            {/* Grid & Listview btns */}
            <div className="grid-list-icons">
              <AppButton
                classes={`${isCardView && 'active'} gridtype unset-all`}
                onlyIcon
                handleClick={() => handleToggleView('grid')}
                name={<Apps className="cl-icons" />}
              />
              <AppButton
                classes={`${!isCardView && 'active'} gridtype unset-all`}
                onlyIcon
                handleClick={() => handleToggleView('list')}
                name={<FormatListBulleted className="cl-icons ml10" />}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 d-flex align-items-start mb-20">
            <AppTags
              tags={updatedAdminTag}
              classes={`searchTags ${filteredTags.length > 0 ? 'h-45r' : ''}`}
              handleFilter={handletagRoute}
              clearFilter={handleClearFilter}
              hasClear={hasClear}
              filteredTags={filteredTags}
              iconClass="filtered__tags"
              noTags
            />
          </div>
        </div>
      </div>
      <AdvanceFilters showFilter={showFilter} setShowFilter={setShowFilter} />
    </div>
  );
}

export default Toolbar;
