/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable  import/no-extraneous-dependencies */
import { bool, string } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './video.scss';
import 'videojs-overlay';
import 'videojs-overlay/dist/videojs-overlay.css';

const VideoPlayer = ({ path, controls }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const options = {
    autoplay: false,
    controls,
    responsive: true,
    fluid: true,
    sources: path
  };
  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Load video when it becomes visible
          videoRef.current.src = path;
          observer.unobserve(entry.target);
        }
      });
    };

    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // Adjust as needed
    };

    const observer = new IntersectionObserver(observerCallback, option);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [path]);
  if (!controls)
    return (
      <>
        <video ref={videoRef} className="asset-fit" controls={controls}>
          <track kind="captions" />
          <source src={path} />
          Your browser does not support the video tag.
        </video>
        <i className="cus-icons playbtn" />
      </>
    );
  const buttonOptions = (player, btnOption) => {
    const myButton = player.addChild('button');
    // Getting html DOM
    const myButtonDom = myButton.el();
    btnOption.class.map((btnClass) => myButtonDom.classList?.add(btnClass));
    myButtonDom.innerHTML = `<i class="custom-payer-ic ${btnOption.iClass}"/>`;
    myButtonDom.onclick = btnOption.handleClick;
  };
  const buttonOptionsIcon = (player, btnoptions) => {
    const hasIcon = document.getElementsByClassName(btnoptions.iClass);
    if (hasIcon.length === 0) {
      const myButtonDom = document.createElement('button');
      btnoptions.class.map((btnClass) => myButtonDom.classList?.add(btnClass));
      myButtonDom.innerHTML = `<span class="${btnoptions.iClass}"/>`;
      myButtonDom.onclick = btnoptions.handleClick;
      const controlBar = document.getElementsByClassName('vjs-control-bar')[0];
      const insertBeforeNode = document.getElementsByClassName(
        'vjs-subs-caps-button'
      )[0];
      controlBar.insertBefore(myButtonDom, insertBeforeNode);
    }
  };

  const handleOverlay = () => {
    document
      .getElementsByClassName('vjs-overlay')[0]
      .classList?.toggle('vjs-hidden');
  };
  const handleForward = () => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() + 10);
  };
  const handleBackward = () => {
    const player = playerRef.current;

    player.currentTime(player.currentTime() - 10);
  };
  const handlePlay = (e) => {
    e.target.classList.add('hide');
    const player = playerRef.current;
    player.play();
  };
  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      playerRef.current = videojs(videoElement, options, function () {
        const myPlayer = this;
        const seekerButtons = document.getElementsByClassName(
          'custom-player-button'
        );
        myPlayer.overlay({
          content: '<p>Default overlay content</p>',
          debug: true,
          overlays: [
            {
              content: `
              <div class="playerOverlay">
              <div class="overlay-close" onClick="document.getElementsByClassName('vjs-overlay')[0].classList.add('vjs-hidden')"><i class="cus-icons ic-close"></i></div>
              <div class="overlay-description">
          <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p><p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
          </div>
          
              </div>`,
              start: 'showHideTextTrack',
              end: 'showHideTextTrack',
              align: 'bottom'
            }
          ]
        });

        myPlayer.on('pause', function () {
          seekerButtons[0]?.classList?.add('seek-show');
          seekerButtons[1]?.classList?.add('seek-show');
          myPlayer.controlBar.show();
        });
        myPlayer.on('play', function () {
          seekerButtons[0]?.classList?.remove('seek-show');
          seekerButtons[1]?.classList.remove('seek-show');
          myPlayer.controlBar.show();
        });
        myPlayer.on('mouseover', function () {
          seekerButtons[0]?.classList?.add('seek-show');
          seekerButtons[1]?.classList?.add('seek-show');
          myPlayer.controlBar.show();
        });
        myPlayer.on('mouseleave', function () {
          seekerButtons[0]?.classList?.remove('seek-show');
          seekerButtons[1]?.classList?.remove('seek-show');
          myPlayer.controlBar.hide();
        });
      });

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player?.src(options.sources);
      const forwardOptions = {
        class: ['custom-player-button', 'button-forward'],
        iClass: 'ic-forward',
        handleClick: handleForward
      };
      const backwardOptions = {
        class: ['custom-player-button', 'button-backward'],
        iClass: 'ic-backward',
        handleClick: handleBackward
      };

      const textTrack = {
        class: ['button-txttrack', 'vjs-control', 'vjs-button'],
        iClass: 'vjs-icon-subtitles',
        handleClick: handleOverlay,
        controlBar: true
      };
      buttonOptions(player, forwardOptions);
      buttonOptions(player, backwardOptions);
      // buttonOptionsIcon(player, textTrack);
    }
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Load video when it becomes visible
          videoRef.current.src = path;
          observer.unobserve(entry.target);
        }
      });
    };

    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // Adjust as needed
    };

    const observer = new IntersectionObserver(observerCallback, option);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [options, videoRef, path]);
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);
  return (
    <div className="video-element">
      <video ref={videoRef} className="video-js asset-fit vjs-default-skin" />
      <i className="cus-icons playbtn detail" onClick={handlePlay} />
    </div>
  );
};
VideoPlayer.defaultProps = {
  controls: false
};
VideoPlayer.propTypes = {
  path: string.isRequired,
  controls: bool
};

export default VideoPlayer;
