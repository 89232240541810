/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import './Signin.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import AppLogo from '../components/AppLogo/AppLogo';
import AppInput from '../components/AppFormComponents/AppInput';
import { onLogin } from '../redux/actions/userInfoAction';

const SignInValidation = Yup.object().shape({
  email: Yup.string()
    .required('Please enter valid email')
    .email('Please enter valid email'),
  password: Yup.string().required('Please enter valid password')
});
const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();
  const year = new Date().getFullYear();
  return (
    <div className="sign-in">
      <div className="sign-in-wrapper">
        <div className="container">
          <div className="row">
            <div className="ps-0 col-lg-4 col-md-12 col-sm-12">
              <div className="intro-section">
                <AppLogo logoStyle={{ width: '100%' }} />
                <h5>
                  Welcome to <br /> CE Assets Library
                </h5>
                <div className="divider" />
                <p className="copyright-heading">
                  Copyright © Content Enablers {year}
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <Formik
                innerRef={formRef}
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={SignInValidation}
                onSubmit={async (values) => {
                  try {
                    const signInvalues = await dispatch(onLogin({ ...values }));
                    const from = location.state?.from?.pathname || '/';
                    if (signInvalues) {
                      navigate(from, { replace: true });
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({ handleChange, handleSubmit }) => (
                  <form className="signup-form">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <AppInput
                        id="email"
                        label="Email"
                        maxLength="150"
                        autoComplete="off"
                        name="email"
                        onChange={handleChange}
                        placeholder="Enter email"
                      />
                      <p className="error-msg" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <AppInput
                        type="password"
                        id="password"
                        label="Password"
                        className="form-control"
                        maxLength="30"
                        autoComplete="off"
                        name="password"
                        onChange={handleChange}
                        placeholder="Enter password"
                      />
                      <p className="error-msg" />
                      {/* <p className="error-msg">
                        {touched.password && errors.password}
                      </p> */}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-sign"
                      onClick={handleSubmit}
                    >
                      SIGN IN
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
