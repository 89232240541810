import PropTypes from 'prop-types';
import React from 'react';

function ErrorHandler({ error }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
}

ErrorHandler.defaultProps = {
  error: {
    message: 'Internal Server Error'
  }
};

ErrorHandler.propTypes = {
  error: {
    message: PropTypes.string
  }
};

export default ErrorHandler;
