/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './RichTextEditor.scss';
import { setBlockData, getSelectedBlocksMetadata } from 'draftjs-utils';
import CustomStyle from './CustomStyle';

const RichTextEditor = ({ data, eventHandler, styles }) => {
  if (!data) {
    data = '<p></p>';
  }

  // using to control two-time function calls
  // eslint-disable-next-line no-unused-vars
  const [customStyles, setCustomStyles] = useState([]);
  const [chunkRendereCheck, setChunkRendereCheck] = useState(false);
  const [newstyleUpdateCheck, setnewstyleUpdateCheck] = useState(false);

  // node.attributes converted to Style Objects
  const nodeAttributesToObj = (attrs) => {
    const objAttrs = { style: null };
    for (let i = attrs.length - 1; i >= 0; i--) {
      if (attrs[i].name !== 'style') {
        if (attrs[i].name && attrs[i].value) {
          objAttrs[attrs[i].name] = attrs[i].value;
        }
      } else {
        const stylesInText = attrs[i].value.split(';');
        const styles = stylesInText.reduce((alum, style) => {
          const components = style.split(':');
          if (components[0] && components[1]) {
            let styleName = components[0].replace('-', '_');
            if (styleName.includes('-'))
              styleName = styleName.replace('-', '_');
            alum[styleName] = `${components[1]}`;
          }
          return alum;
        }, {});
        objAttrs.style = styles;
      }
    }
    return objAttrs;
  };

  // htmlToDraft Package optional Function
  function customChunkRenderer(nodeName, node) {
    const allowedNode = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'li'];
    if (allowedNode.includes(nodeName)) {
      let objAttrs = {};
      let blockCount = {};
      if (node.hasAttributes()) {
        objAttrs = nodeAttributesToObj(node.attributes);
      }
      blockCount = { nodeName, node, style: objAttrs.style };
      customStyles.push(blockCount);
      setChunkRendereCheck(true);
    }
  }

  // Adding CustomStyles to the ContentBlock inside
  const newstyleUpdate = (content) => {
    setnewstyleUpdateCheck(true);
    const styledata = [];
    if (customStyles.length > 0) {
      customStyles.forEach((el, index) => {
        const styleArray = [];
        if (
          el?.style?.line_height ||
          el.style?.list_style_type ||
          el.style?.list_style_position
        ) {
          el.style?.line_height &&
            styleArray.push(['line-height', el.style?.line_height]);
          el.style?.text_align &&
            styleArray.push(['text-align', el.style?.text_align]);
          el.style?.list_style_type &&
            styleArray.push(['list-style-type', el.style?.list_style_type]);
          el.style?.list_style_position &&
            styleArray.push([
              'list-style-position',
              el.style?.list_style_position
            ]);
          el.style?.list_style_position &&
            styleArray.push(['padding_left', el.style?.padding_left]);
          styledata.push({
            blockindex: index,
            blockStyle: styleArray
          });
        }
      });
    }
    styledata.forEach((e) => {
      if (content.contentBlocks[e.blockindex]._map._root.entries[3][1]._root) {
        content.contentBlocks[
          e.blockindex
        ]._map._root.entries[3][1]._root.entries = e.blockStyle;
      }
    });
    return content;
  };

  // ContentBlock rewrite to Add New Style
  const contentBlock = htmlToDraft(
    data,
    !chunkRendereCheck && customChunkRenderer
  );
  const updatedContentBlock = !newstyleUpdateCheck
    ? newstyleUpdate(contentBlock)
    : contentBlock;
  const contentState = ContentState.createFromBlockArray(
    updatedContentBlock.contentBlocks
  );
  const [editorState, setEditorstate] = useState(
    EditorState.createWithContent(contentState)
  );

  const onEditorStateChange = (state) => {
    setEditorstate(state);
  };

  /** Initial save button active check TODO */
  useEffect(() => {
    try {
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      console.debug(html);
      eventHandler(html);
    } catch (e) {
      console.debug(e);
    }
  }, [editorState]);

  const editorStyle = {
    // height: height ? height : window.innerHeight - 500,
    padding: '.5rem',
    backgroundColor: '#e9ecef',
    minHeight: styles?.minHeight ? `${styles?.minHeight}px` : '200px'
  };

  /* text align time this function handle the list points align as well */
  const listPointAlign = () => {
    let nextStyleObject = {};
    const currentStyleMap = getSelectedBlocksMetadata(editorState);
    nextStyleObject = { ...nextStyleObject, 'list-style-position': 'inside' };
    currentStyleMap.forEach((value, key) => {
      nextStyleObject[key] = value;
    });
    const newState = setBlockData(editorState, nextStyleObject);
    if (newState) {
      onEditorStateChange(newState);
    }
  };

  /**
   * concentrate inside line-height applied after
   * it will be fetched and add the class name to editor div
   * */
  function blockStyleFn(block) {
    const blockAlignment = block.getData() && block.getData().get('text-align');
    const blockLineHeight =
      block.getData() && block.getData().get('line-height');
    const blockLineType =
      block.getData() && block.getData().get('list-style-type');
    const listPosition = getSelectedBlocksMetadata(editorState)?.get(
      'list-style-position'
    );
    if (
      (blockAlignment === 'center' || blockAlignment === 'right') &&
      !listPosition
    )
      listPointAlign();
    const styleString = [];
    if (blockAlignment) {
      styleString.push(`rdw-${blockAlignment}-aligned-block`);
    }
    if (blockLineHeight) {
      const blockLineHeightString = `rdw-lineheight-${blockLineHeight}`.replace(
        '.',
        '-'
      );
      styleString.push(blockLineHeightString);
    }
    if (blockLineType) {
      let className = `rdw-list-style-type-${blockLineType}`.replace('.', '-');
      if (className.includes('.')) className = className.replace('.', '-');
      styleString.push(className);
    }
    return styleString.length ? styleString.join(' ') : '';
  }

  return (
    <div className="ck-editor-container">
      <Editor
        editorState={editorState}
        wrapperClassName="richtxt-editor-wrapper-class"
        editorStyle={editorStyle}
        blockStyleFn={blockStyleFn}
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          // options: ['inline', 'blockType', 'fontSize', 'textAlign',
          //           'history', 'colorPicker'],
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'colorPicker',
            'link',
            'textAlign',
            'remove',
            'history'
          ],
          inline: {
            options: ['italic', 'bold'],
            bold: { className: 'demo-option-custom' },
            italic: { className: 'demo-option-custom' },
            underline: { className: 'demo-option-custom' },
            strikethrough: { className: 'demo-option-custom' },
            monospace: { className: 'demo-option-custom' },
            superscript: { className: 'demo-option-custom' },
            subscript: { className: 'demo-option-custom' }
          },
          blockType: {
            className: 'demo-option-custom-wide',
            dropdownClassName: 'demo-dropdown-custom'
          },
          fontSize: {
            className: 'demo-option-custom-medium',
            options: [
              8, 9, 10, 11, 12, 14, 16, 18, 21, 24, 26, 28, 30, 36, 48, 60, 72,
              96
            ]
          },
          // fontFamily: { className: 'demo-option-custom-medium' }
          fontFamily: {
            options: [
              'Arial',
              'Georgia',
              'Impact',
              'Tahoma',
              'Times New Roman',
              'Verdana',
              'WorkSansRegular',
              'Roboto'
            ],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined
          }
        }}
        localization={{
          locale: 'en'
        }}
        toolbarCustomButtons={[
          <CustomStyle type="line-height" />,
          <CustomStyle type="list-style-type" />,
          <CustomStyle type="quote" />
        ]}
      />
    </div>
  );
};

RichTextEditor.propTypes = {
  /** data props used to map the content innerhtml to the  server */
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.string
};

export default RichTextEditor;
